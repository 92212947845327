import { Link } from 'react-router-dom';

export default function Terms() {
    return (
        <main
            className="block my-6 mx-auto leading-7 text-gray-800 md:my-12 max-w-4xl"
        >
            <article className="my-0 text-gray-800 lg:my-0 md:my-0">
                <div
                    className="overflow-hidden my-12 text-base leading-normal bg-gray-200 p-6 md:text-lg space-y-6"
                >
                    <h2
                        className="mx-0 mt-0 mb-10 text-3xl leading-9 text-red-900 md:text-6xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        Terms & Conditions
                    </h2>

                    <div className="text-2xl font-bold leading-6">
                        1. INTRODUCTION
                    </div>
                    <div className="ml-2 space-y-3">
                        <div>
                            1.1 By entering the Fill the Frappé competition (the "Competition"), all participants will
                                be deemed to have accepted these terms and conditions in full. All entry instructions
                                form part of these terms and conditions.
                        </div>
                        <div>
                            1.2 The Competition is open to all employees of Costa Coffee or its affiliated franchise
                                partners operating under the 'Costa' brand and who are aged 16 or over and employed
                                at the date on which they enter the Competition and at the date of delivery of the prize.

                                <br />

                                The company is Costa Limited, a company registered in England with company number
                                01270695, and registered office Costa Limited, 3 Knaves Beech Business Centre,
                                Davies Way, Loudwater, High Wycombe, Buckinghamshire, HP10 9QR
                        </div>
                        <div>
                            1.3 Entry to the Competition opens at 07:00 UK Friday 30 June 2023 and closes at 23:59
                                UK time on Thursday 27 July 2023 (the “Competition Period”). No entries received after
                                the Competition Period has ended will be eligible to win a prize.
                        </div>
                    </div>


                    <div className="text-2xl font-bold leading-6">
                        2. HOW TO ENTER THE COMPETITION
                    </div>

                    <div className="ml-2 space-y-3">
                        <div>

                            2.1 To enter the Competition, go to the website <a
                            href="https://www.costachallenge.com">www.costachallenge.com</a>
                            and enter the following details on the registration form:
                        </div>
                        <div className="ml-4">
                            <div>
                                2.1.1 Full Name;
                            </div>
                            <div>
                                2.1.2 Email (work or personal);
                            </div>
                            <div>
                                2.1.3 Business Area (Store Teams and Support Teams);
                            </div>
                            <div>
                                2.1.4 Store Number or Office Location
                            </div>
                        </div>

                        <div className="italic">
                            There is no limit to the number of times you may enter the competition.
                        </div>

                        <div>
                            2.2  Following the end of the Competition Period, all eligible entries will be collated and
                            shown on a leaderboard – the top entry, based on the leaderboard generated by the
                            online game, will be displayed.
                        </div>
                        <div>
                            2.3 The Winners will be contacted directly at the email address registered, within two
                            weeks of the end of the Competition Period.
                        </div>
                        <div>
                            2.4 The Top 20 on the leaderboard each week will receive a Costa Goodie bag (contents
                            to be confirmed, and the prize will be sent within 40 days of the end of the competition.
                        </div>
                        <div>
                            2.5 Participants ranking between 21-50 each week will receive a Costa Coffee branded
                            flamingo drinks holder, and the prize will be sent within 40 days of the end of the
                            Competition.
                        </div>
                    </div>

                    <div className="text-2xl font-bold leading-6">
                        3. CONDITIONS
                    </div>


                    <div className="ml-2 space-y-3">
                        <div>
                            3.1 There is no cash alternative to the prize available. Prizes are non-transferable and
                            cannot be resold. Unless otherwise agreed in writing by the company, the prizes will only
                            be awarded to the Winner.
                        </div>
                        <div>
                            3.2 The Winner agrees to participate in reasonable publicity as may be required by the
                            company in respect of the Competition. The company reserves the right to publish the
                            Winner’s name, photograph, and business area on its website, Facebook page, Instagram
                            page, Twitter account, or Weibo, or Linkedin page or to publicise the Competition and the
                            prizegiving presentation within a year of the Competition.
                        </div>
                        <div>
                            3.3 To the maximum extent permitted by law, by entering the Competition you grant the
                            Company an irrevocable, royalty-free, non-exclusive, worldwide licence to use any
                            content uploaded as part of your entry and, if you are a Winner any recording (whether
                            photographic, video or otherwise) for any purpose as the company sees fit and agree
                            that you will not grant or permit any other third party the right to use your entry for
                            marketing, promotional or other commercial purposes.
                        </div>
                        <div>
                            3.4 The company reserves the right at any time, in its absolute discretion, to:
                        </div>
                        <div className="ml-4">
                            <div>
                                3.4.1 verify the eligibility of any participant (including their age and place of residence);
                            </div>
                            <div>
                                3.4.2 disqualify any participant found to be abusing or tampering with the operation of
                                the Competition or entering using fraudulent means; and
                            </div>
                            <div>
                                3.4.3 disqualify participants who, in the company's opinion, has acted in a way that is
                                contrary to these terms and conditions or the spirit in which the Competition is intended
                                to be conducted.
                            </div>
                        </div>
                    </div>

                    <div className="text-2xl font-bold leading-6">
                        4. LIABILITY
                    </div>

                    <div className="ml-2 space-y-3">
                        <div>
                            4.1 Save as otherwise set out in this clause 5 and to the maximum extent permitted by
                            applicable law, the company excludes liability for any losses, damages and claims
                            incurred by any person in connection with the Competition
                        </div>
                        <div>
                            4.2 Nothing in these terms and conditions excludes or limits the company's liability for:
                        </div>
                        <div className="ml-4">
                            <div>
                                4.2.1 death or personal injury caused by the company's negligence;
                            </div>
                            <div>
                                4.2.2 fraud or fraudulent misrepresentation; or
                            </div>
                            <div>
                                4.2.3 any matter in respect of which it would be unlawful for the company to exclude or
                                restrict its liability.
                            </div>
                        </div>
                        <div>
                            4.3 In no event will the company be liable to you for any loss of profits, loss of revenue,
                            loss of contracts, failure to realise anticipated savings or for any indirect or consequential
                            loss, whether arising from negligence, breach of contract or otherwise.
                        </div>
                        <div>
                            4.4 The company's total liability to you for any loss or damage arising out of or in
                            connection with these terms and conditions and the Competition, whether in contract
                            (including under any indemnity), tort (including negligence) or otherwise shall be limited
                            in the aggregate (i.e. whether in relation to a single claim or multiple claims) to a sum
                            equal to £100.
                        </div>
                    </div>


                    <div className="text-2xl font-bold leading-6">
                        5. GENERAL
                    </div>

                    <div className="ml-2 space-y-3">
                        <div>
                            5.1 For questions regarding the Competition, email
                            <a href="mailto:internalcommunications@costacoffee.com"> internalcommunications@costacoffee.com</a>
                        </div>
                        <div>
                            5.2 The company reserves the right to extend, withdraw, alter or suspend the
                            Competition or these terms and conditions at any time if circumstances beyond its
                            control make this unavoidable.
                        </div>
                        <div>
                            5.3 All personal data submitted in connection with the Competition will be processed by
                            the company for the purposes of administering and managing the Competition and prizes
                            (where applicable) and verifying the eligibility of each participant. The company is
                            committed to protecting the privacy of all participants. Data that is collected from or
                            about participants will be used in accordance with the company's Privacy Policy, a copy
                            of which can be found at <a href="https://www.costa.co.uk/privacy-policy/">https://www.costa.co.uk/privacy-policy/</a>
                        </div>
                        <div>
                            5.4 These terms and conditions constitute the entire agreement between you and the
                            company in relation to its subject matter. They replace and extinguish all prior
                            agreements, draft agreements, arrangements, collateral warranties, collateral contracts,
                            statements, assurances, representations, and undertakings of any nature made by or on
                            behalf of you and the company, whether oral or written, in relation to that subject
                            matter. You acknowledge that in entering into these terms and conditions no oral or
                            written statements, collateral or other warranties, assurances, representations or
                            undertakings have been relied on which were made by or on behalf of you or the
                            company in relation to the subject matter of these terms and conditions at any time
                            before you entered the Competition (together "Pre-Contractual Statements"), other than
                            those which are set out in these terms and conditions. You hereby waive all rights and
                            remedies which might otherwise be available to you in relation to such Pre Contractual
                            Statements. Nothing in this clause will exclude or restrict the company's liability arising
                            out of any pre-contract fraudulent misrepresentation or fraudulent concealment.
                        </div>
                        <div>
                            5.5 The Competition and these terms and conditions, and any dispute or claim arising out
                            of or in connection with them, are governed by English law. You and the company
                            irrevocably agree that the courts of England and Wales shall have the exclusive
                            jurisdiction to settle any dispute or claim that arise out of or in connection with the
                            Competition and/or these terms and conditions, save that nothing in these terms and
                            conditions will preclude the company from obtaining injunctive or other interlocutory
                            relief in any court of competent jurisdiction.
                        </div>
                    </div>
                </div>
            </article>

            <div className="mt-8">
                <Link to="/" className="bg-white rounded-md p-3 text-app-plum">
                    Go Back
                </Link>
            </div>
        </main>
    );
}

import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import Event from '../Event';
import Toast from '../Toast';

class HttpBase {
    /**
     * @var client
     * @type {null}
     */
    client = null;

    /**
     * @method getClient
     * @return {Echo}
     */
    getClient = () => {
        if (this.client === null) {
            let headers = {};

            this.client = axios.create({
                baseURL: process.env.MIX_API_BASE,
                validateStatus: function(status) {
                    return status >= 200 && status <= 399; // default
                },
                headers
            });

            this.handleCSRFToken();
            this.setDeviceTimeZone();

            this.client.interceptors.response.use((response) => {
                return response;
            }, (error) => {
                if (error.response.status === 401) {
                    Event.emit('api-connection-expired');
                } else if (error.response.status >= 500 && error.response.status <= 599) {
                    Toast.error();
                }

                return Promise.reject(error);
            });
        }

        return this.client;
    };

    /**
     * @method setBearerToken
     * @param {string} token
     * @return {Promise<void>}
     */
    setBearerToken = async token => {
        this.client.defaults.headers["Authorization"] = "Bearer " + token;
    };

    /**
     * @method handleCSRFToken
     */
    handleCSRFToken = () => {
        // Set CSRF Token.
        const token = this.getCSRFToken();

        if (token) {
            this.client.defaults.headers.common['X-CSRF-TOKEN'] = token
        } else {
            console.error(
                'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token'
            );
        }

        // Refresh CSRF Token if expired.
        // (https://blog.anasmazouni.dev/solving-laravel-vue-spa-419-token-mismatch/)
        createAuthRefreshInterceptor(this.client, this.refreshCSRFToken, {
            statusCodes: [419],
        });

        // Using a second interceptor to inject the newly fetched CSRF token to all requests
        this.client.interceptors.request.use((request) => {
            request.headers['X-CSRF-TOKEN'] = this.getCSRFToken();
            return request;
        });
    }

    /**
     * @method getCSRFToken
     * @return {string}
     */
    getCSRFToken = () => {
        const token = document.head.querySelector(
            'meta[name="csrf-token"]'
        );

        return token?.content;
    };

    /**
     * @method refreshCSRFToken
     *
     * The following method adds an interceptor that renews the CSRF token whenever
     * the app recieves a token mismatch error.
     * 
     * @return {Promise<void>}
     */
    refreshCSRFToken = () => {
        return new Promise((resolve, reject) => {
            axios.get('/')
                .then(({ data }) => {
                    const wrapper = document.createElement('div');
                    wrapper.innerHTML = data;
                    return wrapper
                        .querySelector('meta[name=csrf-token]')
                        ?.getAttribute('content');
                })
                .then((token) => {
                    document
                        .querySelector('meta[name=csrf-token]')
                        ?.setAttribute('content', token || '')
                    resolve();
                })
                .catch(() => reject());
        });
    };

    /**
     * @method setDeviceTimeZone
     */
    setDeviceTimeZone = () => {
        this.client.defaults.headers.common['DEVICE-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
}

export default new HttpBase();

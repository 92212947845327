import AdminIndex from 'Pages/Admin';

import ReportsIndex from 'Pages/Admin/Reports';
import ImportsIndex from 'Pages/Admin/Imports';
import StatsIndex from 'Pages/Admin/Stats';

import UsersIndex from 'Pages/Admin/Users';
import UsersShow from 'Pages/Admin/Users/show';
import UsersStore from 'Pages/Admin/Users/store';
import UsersUpdatePassword from 'Pages/Admin/Users/UpdatePassword';

import GroupsIndex from 'Pages/Admin/Groups';
import GroupsStore from 'Pages/Admin/Groups/store';

import TranslationsIndex from 'Pages/Admin/Translations';
import TranslationsShow from 'Pages/Admin/Translations/show';

import PushNotificationsIndex from 'Pages/Admin/PushNotifications';
import PushNotificationsStore from 'Pages/Admin/PushNotifications/store';

export default [
    { path: '/admin', component: AdminIndex, guards: ['admin'] },

    { path: '/admin/reports', component: ReportsIndex, guards: ['admin'] },
    { path: '/admin/imports', component: ImportsIndex, guards: ['admin'] },
    { path: '/admin/stats', component: StatsIndex, guards: ['admin'] },

    { path: '/admin/users', component: UsersIndex, guards: ['admin'] },
    { path: '/admin/users/show/:user', component: UsersShow, guards: ['admin'] },
    { path: '/admin/users/store/:user?', component: UsersStore, guards: ['admin'] },
    { path: '/admin/users/update-password/:user?', component: UsersUpdatePassword, guards: ['admin'] },

    { path: '/admin/groups', component: GroupsIndex, guards: ['admin'] },
    { path: '/admin/groups/store/:group?', component: GroupsStore, guards: ['admin'] },

    { path: '/admin/translations', component: TranslationsIndex, guards: ['admin'] },
    { path: '/admin/translations/show/:group/:key', component: TranslationsShow, guards: ['admin'] },

    { path: '/admin/push-notifications', component: PushNotificationsIndex, guards: ['admin'] },
    { path: '/admin/push-notifications/store/:pushNotification?', component: PushNotificationsStore, guards: ['admin'] },
];

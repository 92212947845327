import React from 'react';
import Header from "Components/Header";
import Footer from "Components/Footer";

/**
 * @function GameBackground
 * @param {JSX.Element} children
 * @param {string} screen
 * @return {JSX.Element}
 * @constructor
 */
const GameBackground = ({children, screen}) => {
    return (
        <React.Fragment>
            <Header />

            <div className={
                `w-full h-full flex ${screen === 'game' ? 'lg:items-center' : 'items-center'} justify-center bg-purple-dark overflow-y-auto lg:overflow-y-hidden`
            }>
                {children}
            </div>

            <Footer/>
        </React.Fragment>
    );
};

export default GameBackground;

import {PlayGameButton} from 'Components/Games/Partials';
import React from 'react';
import Base from 'Components/Games/Base';
import {Link, withRouter} from 'react-router-dom';
import Game from './Game';
import { DateTime } from 'luxon';

class CatchGame extends Base {
    release = Math.floor(Math.abs(DateTime.fromISO(process.env.MIX_RELEASE).diffNow().as('weeks'))) + 1;

    /**
     * @var gameName
     * @type {string}
     */
    gameName = 'catch-game';

    game = {
        method: 'component',
        component: Game
    };

    componentDidMount = () => {
        window.addEventListener('message', this.handleMessage);

        // if (this.release >= 4) {
        //     this.props.history.push('/game-over');
        // }
    }

    /**
     * @method renderWelcome
     * @return {JSX.Element}
     */
    renderInstructions = () => {
        return (
            <div
                className="bg-games-catch w-full h-full bg-no-repeat bg-cover relative flex overflow-y-auto ">
                <div className="my-auto w-full justify-center mx-auto max-w-2xl ">
                    <div className="my-4 lg:mt-8 w-full">
                        <div className="text-center rounded-lg border-8 border-app-plum max-w-4xl bg-app-plum bg-no-repeat p-4">
                            <div className={
                                'text-app-yellow font-costa-display-wave md:text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl lg:mb-4 ' +
                                'xl:mb-6 2xl:mb-12 font-bt-curve-bold uppercase'
                            }>
                                Fill the Frappé
                            </div>

                            <div className={
                                'text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl ' +
                                '2xl:text-2xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold'
                            }>
                                Our bubbles are falling, help us catch them! Catch as many as you can in 30 seconds
                                to fill the Frappé and top the leaderboard!
                            </div>

                            <div className={
                                'text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl ' +
                                '2xl:text-2xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold'
                            }>
                                To play, use your keyboard arrows or for mobile and tablet devices, use the arrows on the left and right of the screen.
                            </div>

                            <div className={
                                'text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl ' +
                                '2xl:text-2xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold'
                            }>
                                Are you ready for the challenge?
                            </div>

                            <PlayGameButton handleGoToScreen={() => this.handleGoToStage('game')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    /**
     * @method renderTimesUp
     * @return {JSX.Element}
     */
    renderTimesUp = () => {
        return (
            <div
                className="bg-games-catch w-full h-full bg-no-repeat bg-cover relative flex overflow-y-auto ">
                <div className="my-auto w-full justify-center mx-auto max-w-2xl ">
                    <div className="my-4 lg:mt-8 w-full">
                        <div className="text-center rounded-lg border-8 border-app-plum max-w-4xl bg-app-plum bg-no-repeat p-4">
                            <div
                                className="text-app-yellow font-costa-display-wave md:text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold uppercase">
                                Nice try!
                            </div>

                            <div
                                className="text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl 2xl:text-2xl lg:mb-4 xl:mb-11 2xl:mb-12 font-bt-curve-bold">
                                You've saved {this.points ?? 0} bubbles! Head to the leaderboard to see if you’re a Frappé Champion
                            </div>

                            <PlayGameButton handleGoToScreen={() => this.handleGoToStage('game')}/>

                            <Link to="/" className={
                                'px-2 sm:px-2 mb-8 py-1 sm:py-1 g:px-4 lg:py-2 rounded-md bg-white text-app-plum text-md ' +
                                'lg:text-xl bg-white transition duration-200 transform hover:scale-110 ' +
                                'font-bt-curve-bold ml-5'
                            }>
                                Leaderboard
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default withRouter(CatchGame);

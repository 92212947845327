import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Base from './Base';

/**
 * @function DangerButton
 * @param {function} onClick
 * @param {string} className
 * @param {string} text
 * @param {boolean} working
 * @param {boolean} disabled
 * @return {JSX.Element}
 * @constructor
 */
const DangerButton = ({ onClick, className, text, working, disabled }) => {
    let classes = `
        inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md
        text-white bg-red-600 transition duration-150 ease-in-out flex items-center
        ${
            !disabled && !working 
                ? 'hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500' 
                : 'opacity-50 cursor-default'
        }
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <Base
            className={classes}
            type={onClick !== undefined ? 'button' : 'submit'}
            onClick={onClick}
            disabled={disabled}
            working={working}
            text={text}
        />
    );
};

export default DangerButton;

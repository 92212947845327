import React from 'react';
import {Link} from "react-router-dom";
import Cookies from "Services/Cookies";

export default function CookieBanner() {
    return (
        <div className={
            'absolute top-0 w-full bg-app-plum flex flex-col md:flex-row justify-between shadow-lg py-4 px-12'
        }>
            <div className="my-auto text-gray-100 flex-1">
                To improve and personalise your visit, 1st and 3rd party cookies are used. See <Link
                to="/policies/cookie" className="text-gray-100 text-opacity-80 underline">Cookies</Link> for more info. "I
                Decline" continues with necessary cookies, "I Accept" accepts all.
            </div>
            <div className="flex-initial mx-auto md:mx-0">
                <button className="bg-white text-white bg-opacity-10 p-3" onClick={() => {
                    Cookies.setCookie('decline');
                }}>
                    I decline
                </button>
                <button className="bg-white text-white bg-opacity-10 p-3 ml-2" onClick={() => {
                    Cookies.setCookie('accept');
                }}>
                    I accept
                </button>
            </div>
        </div>
    );
}

import { Loading, Pagination } from 'Components/Partials';
import {DateTime} from 'luxon';
import React from 'react';
import Leaderboard from 'Services/Api/Leaderboard';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import {asset} from 'Services/BaseHelpers';
import { Link } from "Components/Utilities";
import { PrimaryButton } from "Components/Button";

export default class Dashboard extends React.Component {
    state = {
        activeWeek: 1,
        leaderboard: null,
        meta: null,
        pos: null,
        loading: false,
    };

    inactive = 'bg-transparent border-app-coral hover:bg-app-brown hover:bg-opacity-100';
    release = Math.floor(Math.abs(DateTime.fromISO(process.env.MIX_RELEASE).diffNow().as('weeks'))) + 1;

    setWeek = (activeWeek) => {
        this.setState({
            activeWeek,
        }, async () => {
            await this.getPos();
            await this.getWeek();
        });
    };

    getWeek = async (page = 1) => {
        this.setState({
            loading: true,
            leaderboard: null,
        });

        const response = await Leaderboard.getLeaderboards({
            week: this.state.activeWeek,
            page,
        });

        if (response.success) {
            this.setState({
                leaderboard: response.data.data,
                meta: response.data.meta,
                loading: false,
            });
        }
    };

    getPos = async () => {
        const res = await Leaderboard.getPosition({week: this.state.activeWeek});

        if (res.success) {
            this.setState({
                pos: res.data.position,
            });
        }
    }

    async componentDidMount() {
        await this.getPos();
        await this.getWeek();
    }

    renderPoints = (points) => {
       // if (this.state.activeWeek === 4) {
            return `${points} Bubbles`;
       // }

        //const time = DateTime.fromMillis(points).toUTC().toFormat('mm:ss:SSS');
        //return time.substring(0, time.length - 1);
    };

    render() {
        const {activeWeek, loading} = this.state;

        return (
            <div className="flex flex-col min-h-screen bg-transparent lg:bg-milkshake bg-contain bg-no-repeat bg-left-bottom">
                <Header />
                <div className="relative flex-1">
                    <div className="max-w-5xl min-w-screen-sm w-full pl-0 lg:pl-44 pr-0 mx-auto">
                        {/*<img alt="Costa"*/}
                        {/*     className="w-52 mx-auto my-6 lg:my-12 absolute top-0 right-0" src="/images/logo.svg" />*/}
                        <h2 className="text-center text-5xl font-costa-display-wave text-app-yellow">
                            Our Top Fillers
                        </h2>

                        <div className="mt-14 mb-8 flex flex-col md:hidden">
                            <div className="w-full flex flex-row">
                                <div className="mx-auto">
                                    <PrimaryButton text="Week One" fontSize="text-lg" className="border-2 w-40"
                                                   inactiveStyles={this.inactive} active={activeWeek === 2}
                                                   onClick={() => this.setWeek(1)} />
                                </div>
                                {this.release >= 1 && (
                                    <div className="mx-auto">
                                        <PrimaryButton text="Week Two" fontSize="text-lg" className="border-2 w-40"
                                                       inactiveStyles={this.inactive} active={activeWeek === 3}
                                                       onClick={() => this.setWeek(2)} />
                                    </div>
                                )}
                            </div>

                            {this.release >= 2 && (
                                <div className="w-full flex flex-row mt-2 md:mt-0 pl-4 md:pl-0">
                                    <div className="mx-auto">
                                        <PrimaryButton text="Week Three" fontSize="text-lg" className="border-2 w-40"
                                                       inactiveStyles={this.inactive} active={activeWeek === 4}
                                                       onClick={() => this.setWeek(3)} />
                                    </div>

                                    {this.release >= 3 && (
                                        <div className="mx-auto">
                                            <PrimaryButton text="Week Four" fontSize="text-lg" className="border-2 w-40"
                                                           inactiveStyles={this.inactive} active={activeWeek === 5}
                                                           onClick={() => this.setWeek(4)} />
                                        </div>
                                    )}

                                    {this.release < 3 && (
                                        <div className="mx-auto w-full w-40" />
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="hidden md:flex flex-row mt-14 mb-8">
                            <div className="mx-auto">
                                <PrimaryButton text="Week One" fontSize="text-lg" className="border-2"
                                               inactiveStyles={this.inactive} active={activeWeek === 1}
                                               onClick={() => this.setWeek(1)} />
                            </div>

                            {this.release >= 2 && (
                                <div className="mx-auto">
                                    <PrimaryButton text="Week Two" fontSize="text-lg" className="border-2"
                                                   inactiveStyles={this.inactive} active={activeWeek === 2}
                                                   onClick={() => this.setWeek(2)} />
                                </div>
                            )}

                            {this.release >= 3 && <>
                                <div className="mx-auto">
                                    <PrimaryButton text="Week Three" fontSize="text-lg" className="border-2"
                                                   inactiveStyles={this.inactive} active={activeWeek === 3}
                                                   onClick={() => this.setWeek(3)} />
                                </div>

                                {this.release >= 4 && (
                                    <div className="mx-auto">
                                        <PrimaryButton text="Week Four" fontSize="text-lg" className="border-2"
                                                       inactiveStyles={this.inactive} active={activeWeek === 4}
                                                       onClick={() => this.setWeek(4)} />
                                    </div>
                                )}
                            </>}
                        </div>

                        <div className="text-xl overflow-y-auto border-t-4 border-b-4 border-app-plum">
                            {this.state.pos && (
                                <div className={
                                    'odd:bg-white odd:bg-opacity-5 w-full text-white grid grid-cols-7 md:grid-cols-10 ' +
                                    'lg:grid-cols-12 p-2'
                                }>
                                    <div
                                        className="col-span-7 md:col-span-10 lg:col-span-12 text-center text-app-brown">
                                        You're in position {this.state.pos}
                                    </div>
                                </div>
                            )}
                            { loading && (
                                <Loading />
                            )}
                            {this.state.leaderboard && (
                                <ul className="grid grid-cols-1 w-full text-white uppercase font-extrabold text-sm md:text-lg" >
                                   { this.state.leaderboard.map(({points, user}, k) => (
                                       <li  className="even:bg-app-yellow even:text-app-plum grid grid-cols-7 lg:grid-cols-12 p-2">
                                            <div  className="col-span-1 lg:col-span-2">{this.state.meta.from + k}</div>
                                            <div className="col-span-3 lg:col-span-4">{user.full_name}</div>
                                            <div className="col-span-2 lg:col-span-4">{user.country ?? user.location }</div>
                                            <div className="col-span-1 lg:col-span-2 ">{this.renderPoints(points)}</div>
                                        </li>
                                   ))}
                                </ul>
                            )}
                            {this.state.leaderboard && this.state.leaderboard.length === 0  && (
                                <div key={"no-leaderboard-message"}
                                     className="odd:bg-app-yellow w-full text-white p-2 uppercase font-extrabold text-center"
                                >
                                    No scores found
                                </div>
                            )}
                        </div>

                        {this.state.meta && this.state.meta.last_page !== 1 && (
                            <div className="my-10 w-full flex">
                                <Pagination
                                    className="mx-auto"
                                    page={this.state.meta.current_page}
                                    goToPage={this.getWeek}
                                    pageCount={this.state.meta.last_page} />
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

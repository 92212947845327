import {NavLink, Link, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBars} from '@fortawesome/free-solid-svg-icons';
import React from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

class Header extends React.Component {
    /**
     * @var navItems
     * @type {string}
     */
    navItems = [
        // Pre auth
        {
            to: "/",
            title: "Leaderboard",
            is_logged_out: true
        },
        // Post auth
        {
            to: "/games/catch-game",
            title: "Play",
            is_logged_in: true
        },
        {
            to: "/faq",
            title: "FAQs",
            is_logged_in: true
        },
        {
            to: "/logout",
            title: "Logout",
            is_logged_in: true
        }
    ];

    /**
     * @var state
     * @type {{showOnMobile: boolean}}
     */
    state = {
        isNavOpen: false,
    }

    /**
     * @method render
     * @returns {JSX.Element}
     */
    render() {
        const {bgColour = 'bg-transparent'} = this.props;
        const {isNavOpen} = this.state;

        return (
            <div className={`${bgColour} text-white px-8 py-6 select-none flex items-start flex-wrap lg:flex-nowrap mb-4`}>
                {!isNavOpen && (
                    <FontAwesomeIcon
                        icon={faBars}
                        size="2x"
                        onClick={() => this.setState({isNavOpen: !isNavOpen})}
                        className="block justify-left  items-start cursor-pointer mr-2 text-white z-30"
                    />
                )}

                {isNavOpen && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="2x"
                        onClick={() => this.setState({isNavOpen: !isNavOpen})}
                        className="block justify-left ml-auto  items-start cursor-pointer mr-2 text-white z-30"
                    />
                )}

                <div className={`
                    ${isNavOpen ? 'flex' : 'hidden text-app-plum'}
                    flex-col lg:items-end p-2 lg:p-0 min-h-fit w-full h-full absolute top-0 bottom-0 right-0 left-0 bg-app-plum bg-cover bg-no-repeat z-20
                `}>
                    <div className="flex flex-col w-full h-full justify-center items-center gap-4 lg:gap-8 text-center text-white">
                        {this.navItems.map((navItem, i) =>
                            this.renderNavItem(navItem, i))
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * @method renderNavItem
     * @param {object} navItem
     * @param {integer} key
     * @returns {JSX.Element}
     */
    renderNavItem = (navItem, key) => {
        return (
            <div key={key}>
                <NavLink
                    className="flex flex-row justify-center items-center gap-2 font-thin text-vm-chat text-app-black tracking-wider uppercase"
                    activeClassName="border-b-2 border-app-red"
                    to={navItem.to}
                    exact={true}
                >
                    {navItem.icon &&
                        <FontAwesomeIcon
                            icon={navItem.icon}
                            size="1x"
                        />
                    }

                    {navItem.title}
                </NavLink>
            </div>
        );
    };
}

export default withRouter(Header);

import React from 'react';

import Label from './Label';

import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import QuillHtmlSourceButton from 'quill-source-code-button';

Quill.register('modules/htmlSource', QuillHtmlSourceButton);

const WYSIWYG = (props) => {
    const {containerClassName, label, placeholder, onChange, value, instructions} = props;

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block', 'link'],

            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // Removed as do not work [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ],
        htmlSource: {},
    };

    return (
        <div className={containerClassName}>
            {label && (<Label label={label} instructions={instructions} />)}

            <div className="bg-white">
                <ReactQuill 
                    theme="snow"
                    value={value ?? null} 
                    onChange={value => onChange(value)}
                    modules={modules}
                />
            </div>
        </div>
    );
};

const RenderWYSIWYG = (props) => {
    const {content} = props;

    return (
        <span 
            className="ql-editor render-ql-editor"
            dangerouslySetInnerHTML={{__html: content}}
        >
        </span>
    );
}

export {
    WYSIWYG,
    RenderWYSIWYG
}

/*
Draft JS

1. yarn add draft-js, draftjs-to-html, html-to-draftjs, react-draft-wysiwyg
2. Import the below at the top of the script.
3. On mount of the component set form.foobar = convertHtmlToDraftEditorState(value).
4. Add the editor to the render() method as per below.
5. Before api submission call convertDraftEditorStateToHtml() on the form.foobar value before passing it off to the api.

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

convertHtmlToDraftEditorState = (html) => {
    if (!html || html === '') {
        return EditorState.createEmpty();
    }

    // We do not use the offical convertFromHTML() DraftJS method 
    // as it does note work with some tags, e.g. superscript.
    const { contentBlocks, entityMap } = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    return EditorState.createWithContent(contentState);
}

convertDraftEditorStateToHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}

<div className="bg-white p-2 z-10">
    <Editor
        editorState={form.foobar}
        wrapperClassName="demo-wrapper z-10"
        editorClassName="demo-editor z-10"
        onEditorStateChange={editorState => handleInput('foobar', editorState)}
    />
</div>
*/
import {DateTime} from 'luxon';
import Point from 'Pages/Games/SearchGame/Game/Screens/Point';
import React from 'react';
import {Link} from 'react-router-dom';
import {asset} from 'Services/BaseHelpers';

export default class Game extends React.Component {
    state = {
        width: 1024,
        foundPoints: {},
        containerScroll: 0,
        timer: '00:00',
    };

    container = null;
    startedAt = DateTime.now();

    weeks = [
        {
            map: '/images/games/search-game/background.jpg',
            points: [
                {
                    img: '/images/games/search-game/1.png',
                    top: '71%',
                    left: '62.8%',
                    scaleUsing: 'height',
                    relativeScale: .4,
                },
                {
                    img: '/images/games/search-game/2.png',
                    top: '33.3%',
                    left: '12%',
                    scaleUsing: 'width',
                    relativeScale: .58,
                },
                {
                    img: '/images/games/search-game/4.png',
                    top: '14.5%',
                    left: '77.8%',
                    scaleUsing: 'height',
                    relativeScale: .7,
                },
                {
                    img: '/images/games/search-game/5.png',
                    top: '7.3%',
                    left: '60%',
                    scaleUsing: 'width',
                    relativeScale: .7,
                },
                {
                    img: '/images/games/search-game/6.png',
                    top: '19.5%',
                    left: '48.5%',
                    scaleUsing: 'width',
                    relativeScale: .55,
                },
                {
                    img: '/images/games/search-game/7.png',
                    top: '32%',
                    left: '84.2%',
                    scaleUsing: 'width',
                    relativeScale: .6,
                },
                {
                    img: '/images/games/search-game/8.png',
                    top: '29.4%',
                    left: '90.2%',
                    scaleUsing: 'width',
                    relativeScale: .6,
                },
                {
                    img: '/images/games/search-game/9.png',
                    top: '33%',
                    left: '46.4%',
                    scaleUsing: 'width',
                    relativeScale: .4,
                },
                {
                    img: '/images/games/search-game/10.png',
                    top: '56%',
                    left: '86.45%',
                    scaleUsing: 'width',
                    relativeScale: 1,
                },
                {
                    img: '/images/games/search-game/11.png',
                    top: '9%',
                    left: '38.3%',
                    scaleUsing: 'width',
                    relativeScale: 1,
                },
                {
                    img: '/images/games/search-game/12.png',
                    top: '75%',
                    left: '46%',
                    scaleUsing: 'width',
                    relativeScale: 1,
                },
                {
                    img: '/images/games/search-game/13.png',
                    top: '60%',
                    left: '35.2%',
                    scaleUsing: 'width',
                    relativeScale: .45,
                },
                {
                    img: '/images/games/search-game/14.png',
                    top: '34.9%',
                    left: '75.5%',
                    scaleUsing: 'width',
                    relativeScale: .9,
                },
                {
                    img: '/images/games/search-game/15.png',
                    top: '79.5%',
                    left: '70%',
                    scaleUsing: 'width',
                    relativeScale: .8,
                },
                {
                    img: '/images/games/search-game/16.png',
                    top: '9%',
                    left: '93%',
                    scaleUsing: 'width',
                    relativeScale: .6,
                },
                {
                    img: '/images/games/search-game/17.png',
                    top: '62.9%',
                    left: '48.6%',
                    scaleUsing: 'width',
                    relativeScale: .7,
                },
                {
                    img: '/images/games/search-game/18.png',
                    top: '29.7%',
                    left: '52%',
                    scaleUsing: 'width',
                    relativeScale: .45,
                },
                {
                    img: '/images/games/search-game/19.png',
                    top: '39.3%',
                    left: '88%',
                    scaleUsing: 'width',
                    relativeScale: .5,
                },
                {
                    img: '/images/games/search-game/20.png',
                    top: '45.5%',
                    left: '64.4%',
                    scaleUsing: 'width',
                    relativeScale: .8,
                },
                {
                    img: '/images/games/search-game/21.png',
                    top: '43.7%',
                    left: '44.8%',
                    scaleUsing: 'width',
                    relativeScale: .6,
                },
                {
                    img: '/images/games/search-game/22.png',
                    top: '59.8%',
                    left: '56%',
                    scaleUsing: 'width',
                    relativeScale: .5,
                },
                {
                    img: '/images/games/search-game/23.png',
                    top: '57%',
                    left: '95%',
                    scaleUsing: 'width',
                    relativeScale: 1,
                },
                {
                    img: '/images/games/search-game/24.png',
                    top: '68.4%',
                    left: '7.4%',
                    scaleUsing: 'width',
                    relativeScale: .6,
                },
                {
                    img: '/images/games/search-game/25.png',
                    top: '82%',
                    left: '21%',
                    scaleUsing: 'width',
                    relativeScale: 1,
                },
                {
                    img: '/images/games/search-game/26.png',
                    top: '84%',
                    left: '36.7%',
                    scaleUsing: 'width',
                    relativeScale: .7,
                },
            ],
        },
    ];

    componentDidMount() {
        this.setWidth();

        window.addEventListener('resize', this.setWidth);

        this.startedAt = DateTime.now();

        setInterval(() => {
            this.setState({
                timer: DateTime.now().diff(this.startedAt).toFormat('mm:ss'),
            })
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setWidth);
    }

    setWidth = () => {
        this.setState({
            width: this.container.clientWidth,
        });
    };

    selectedPoint = (point, points) => {
        const foundPoints = this.state.foundPoints;

        foundPoints[point] = true;

        if (Object.keys(foundPoints).length === points.length) {
            this.props.setPoints(Math.abs(this.startedAt.diffNow().as('milliseconds')));

            this.props.handleTimesUp();
        }

        this.setState({
            foundPoints,
        });
    }

    renderPoint = (
        {
            img, top, bottom, left, right, paddingLeft, paddingRight, scaleUsing, relativeScale: rScale = 1,
        }, k,
    ) => (
        !this.state.foundPoints[k] && (
            <Point key={k} {...this.generic} position={{top, left, bottom, right}} style={{paddingRight, paddingLeft}}
                   selected={() => this.selectedPoint(k, this.data.points)}>
                <img {...{[scaleUsing]: this.size * rScale}} className="w-auto select-none"
                     draggable={false} style={{[scaleUsing]: this.size * rScale}} alt=""
                     src={asset(img)} />
            </Point>
        )
    );

    handleScroll = (e) => {
        this.setState({
            containerScroll: e.target.scrollLeft,
        });
    };

    render() {
        const {width} = this.state;

        this.generic = {
            fontSize: Math.round(Math.max(22 * (width / 1024), 22)),
            padding: Math.round(Math.max(6 * (width / 1024), 6)),
            popoutWidth: Math.round(Math.max(225 * (width / 1024), 225)),
        }

        this.size = Math.round(Math.max(40 * (width / 1024), 40));

        // const week = Math.floor(Math.abs(DateTime.fromISO(process.env.MIX_RELEASE).diffNow().as('weeks')));
        this.data = this.weeks[0];

        const lg = width > 1024;

        return (
            <div className="h-full">
                <div
                    className="w-full h-full overflow-hidden my-auto md:px-8 xl:px-28"
                    style={{
                        scrollBehaviour: 'smooth',
                    }}
                >
                    <div className="overflow-auto flex h-full" onScroll={this.handleScroll}>
                        <div className="w-full min-w-screen-lg max-w-screen-2xl overflow-visible m-auto">
                            <div ref={ref => this.container = ref}
                                 className="w-full min-w-screen-lg max-w-screen-2xl relative">
                                <img alt="Map" src={asset(this.data.map)} draggable={false} className="w-full select-none" />

                                {this.data.points.map(this.renderPoint)}

                                <div
                                    className={
                                        `bg-games-catch-timer ${lg ? 'absolute' : 'fixed top-16 left-4 sm:left-8 md:left-12'} p-4 rounded-lg text-white text-center`
                                    }
                                    style={lg ? {
                                        top: 20,
                                        left: 20 + this.state.containerScroll,
                                    } : {}}
                                >
                                    <div>
                                        {this.state.timer}
                                    </div>
                                    <div>
                                        Score: {Object.keys(this.state.foundPoints).length}/{this.data.points.length}
                                    </div>
                                </div>

                                <Link to="/"
                                      className={`bg-app-brown ${lg ? 'absolute' : 'fixed top-16 right-4 sm:right-8 md:right-12'} p-4 rounded-lg text-white text-center`}
                                      style={lg ? {
                                          top: 20,
                                          left: this.state.width - 78 + this.state.containerScroll, // 1024 - 350
                                      } : {}}>
                                    Exit
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from '../AdminLayout';
import {Alert, Loading, PaginationBar} from 'Components/Partials';
import AdminButtons from 'Components/Partials/AdminButtons';
import Delete from 'Components/Partials/Modals/Delete';
import {PrimaryButton} from 'Components/Button';

import AdminGroupsApi from 'Services/Api/Admin/Groups';

export default class GroupsIndex extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        alert: null,
        groups: [],
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        this.loadGroups();
    }

    /**
     * @method loadGroups
     * @param {integer} page
     */
    loadGroups = async (page = 1) => {
        this.setState({ 
            working: true,
            alert: null,
        });

        const response = await AdminGroupsApi.get(null, {page});

        this.setState({
            working: false,
            groups: response.data,
        });
    }

    /**
     * @method handleDelete
     * @param {object} group
     */
    handleDelete = async (group) => {
        this.setState({ working: true });

        let data = {
            confirmed: true
        };

        const response = await AdminGroupsApi.delete(group.id, '', 204, {data});

        if (response.status !== 204) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message
                },
                working: false
            });
            return;
        }

        this.loadGroups();
    }

    /**
     * @method getButtonsForGroup
     * @param {object} group
     */
    getButtonsForGroup = (group) => {
        return [
            {
                type: "link",
                to: "/admin/groups/store/"+group.id,
                text: "Update"
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'group',
                    onDelete: () => this.handleDelete(group)
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, groups} = this.state;

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Groups
                        </h2>
                    </div>
                </div>

                {!window.base.features.groups &&
                    <p className="m-6 font-bold">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.groups &&
                    <div className="ml-4 mr-4 mt-4">
                        <div>
                            <Link to={"/admin/groups/store"}>
                                <PrimaryButton
                                    text="Click here to add a new Group."
                                />
                            </Link>
                        </div>

                        <div className="mt-8">
                            {working && (<Loading />)}

                            {alert !== null && (<Alert {...alert} />)}

                            {!working &&
                                <>
                                    { groups?.meta?.total == 0 &&
                                        <p>Groups will appear here.</p>
                                    }

                                    { groups?.meta?.total != 0 &&
                                        <>
                                            <div className="overflow-x-auto">
                                                <table className="table-auto">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left p-2">
                                                                Name
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { groups.data.map((group, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="border px-4 py-2">{group.name}</td>
                                                                    <td className="border px-4 py-2 text-center">
                                                                        <AdminButtons buttons={this.getButtonsForGroup(group)} />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {groups && (
                                                <div className="mt-12 mb-4">
                                                    <PaginationBar
                                                        total={groups.meta.total}
                                                        pageCount={groups.meta.last_page}
                                                        page={groups.meta.current_page}
                                                        goToPage={this.loadGroups}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                }
            </AdminLayout>
        )
    }
}

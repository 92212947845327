import React from 'react';

import {Loading} from 'Components/Partials';
import {mergeObjectsIntoPaginationData} from 'Services/BaseHelpers';

export default class PaginationScroll extends React.Component { 
    /**
     * @constructor
     */   
    constructor() {
        super();
        this.containerRef = React.createRef();
    }

    /**
     * @var state
     * @type {{working: boolean}}
     */
    state = {
        working: false,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        const {scrollDown} = this.props;

        this.handlePageChange(1);

        if (!scrollDown) {
            this.scrollToBottom();
        }
    }

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate (prevProps) {
        const {data, scrollToStart} = this.props;

        // Scroll to the start if the "scrollToStart" prop is set to true
        // or if refreshed to the first page.
        if ((scrollToStart && prevProps.scrollToStart !== scrollToStart)
            || (data?.meta?.current_page === 1 && prevProps?.data?.meta?.current_page !== 1)) {

            this.scrollToStart();
        }
    }

    scrollToStart = () => {
        const {scrollDown, scrollToStartCallback} = this.props;

        if (scrollDown) {
            this.scrollToTop();
        } else {
            this.scrollToBottom();
        }

        if (scrollToStartCallback) {
            scrollToStartCallback(false);
        }
    }

    /**
     * @method scrollToBottom
     */
    scrollToBottom = () => {
        if (this.containerRef.current) {
            this.containerRef.current.scrollTop = this.containerRef.current.scrollHeight;
        }
    }

    /**
     * @method scrollToTop
     */
    scrollToTop = () => {
        if (this.containerRef.current) {
            this.containerRef.current.scrollTop = 0;
        }
    }

    /**
     * @method handlePageChange
     * @param {integer} page
     */
    handlePageChange = async (page = null) => {
        const {working} = this.state;
        const {data, apiCall} = this.props;

        if (!working && (!data || data?.meta?.last_page !== data?.meta?.current_page)) {
            if (!page) {
                page = data.meta.current_page + 1;
            }

            this.setState({ 
                working: true,
            });

            const response = await apiCall(page);

            this.handleResponse(response, page);
        }
    };

    handleResponse = (response, page) => {
        // Get the data again to ensure the most up to date data
        // incase the parent updates it.
        const {data, updateData, sameObjectCheck = null} = this.props;

        if (page === 1) {
            var new_data = response.data;
        } else {
            var new_data = mergeObjectsIntoPaginationData(response.data, data.data, true, sameObjectCheck);
        }

        updateData(new_data);

        this.setState({
            working: false,
        });
    }

    /**
     * @method handleScrollDown
     * @param {event} e
     */
    handleScrollDown = async (e) => {
        const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;

        if (bottom) { 
            this.handlePageChange();
        }
    }

    /**
     * @method handleScrollUp
     * @param {event} e
     */
    handleScrollUp = async (e) => {
        const {current} = this.containerRef;

        const top = current.scrollTop === 0;

        if (top) { 
            const height_before = current.scrollHeight;

            await this.handlePageChange();

            // Scroll back to where you were.
            const height_after = current.scrollHeight;
            current.scrollTop = (height_after - height_before);
        }
    }

    /**
     * @method render
     * @return {*}
     */
    render = () => {
        const {working} = this.state;
        const {children, scrollDown, containerClassName} = this.props;

        return (
            <div 
                className={`overflow-auto h-full w-full ${containerClassName}`}
                onScroll={scrollDown ? this.handleScrollDown : this.handleScrollUp}
                ref={this.containerRef}
            >
                {!scrollDown && working && (<Loading size="3x" />)}

                {children}

                {scrollDown && working && (<Loading size="3x" />)}
            </div>
        );
    };
}
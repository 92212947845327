import {faCaretRight} from '@fortawesome/free-solid-svg-icons/faCaretRight';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SimpleSelect from 'Components/Form/SimpleSelect';
import React from 'react';
import Unauthenticated from 'Components/Layouts/Unauthenticated';

import {Checkbox, FormHandler, Input} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Link} from 'react-router-dom';

import Auth from 'Services/Api/Auth/Auth';
import {translation} from 'Services/TranslationHelpers';
import { asset } from "Services/BaseHelpers";

const areas = [
    'Store Teams',
    //'Express',
    'Support Teams',
    //'Franchise Store',
];

const officeLocation = [
    'Dunstable',
    'Wimpole Street',
    'Loudwater',
    'Basildon',
];

const partnerArea = [
    'Individual Franchise',
    'Corporate Franchise',
    'Corporate License',
];

class Register extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your account has been successfully created. You will receive an email shortly to confirm your ' +
        'email address.';

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, setForm, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated width="max-w-4xl w-full px-4 py-8 lg:py-12">
                <div className="text-black text-center">

                    <div className="text-5xl text-app-plum mb-3 font-costa-display-wave uppercase">
                        Enter Your Details To Play The Game
                    </div>

                    <div className="my-3">
                        This is to help us check you work for Costa and, most importantly, get your prize to you safely.
                    </div>

                    <div className="mb-3">
                        Please visit our <Link to="/faq" className="underline">FAQs page</Link> for more information.
                    </div>

                    <form
                        autoComplete="off"
                        className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-6"
                        onSubmit={(e) => {
                            handleSubmit(e, Auth.register, this.success, true)
                        }}
                    >
                        {alert !== null && (
                            <div className="md:col-span-2 text-left">
                                <Alert {...alert} />
                            </div>
                        )}
                        <Input
                            name="full_name"
                            placeholder="Full Name"
                            type="text"
                            value={form.full_name ?? ''}
                            onChange={v => handleInput('full_name', v)}
                            labelTextColor="text-gray-200 text-left"
                        />

                        <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={form.email ?? ''}
                            onChange={v => handleInput('email', v)}
                            labelTextColor="text-gray-200 text-left"
                        />

                        <SimpleSelect
                            placeholder="What's your business area?"
                            selected={form.business_area}
                            options={areas}
                            setSelected={v => {
                                delete form.store_number;
                                delete form.office_location;
                                delete form.partner_area;
                                delete form.partner_company;

                                form.business_area = v;

                                setForm(form);
                            }}
                        />

                        {form.business_area === 'Store Teams' && (
                            <>
                                <Input
                                    name="store_number"
                                    placeholder="What’s your Store Number or Name?"
                                    type="text"
                                    value={form.store_number}
                                    onChange={v => handleInput('store_number', v)}
                                    labelTextColor="text-gray-200 text-left"
                                />

                                <Input
                                    name="country"
                                    placeholder="Country"
                                    type="text"
                                    value={form.country ?? ''}
                                    onChange={v => handleInput('country', v)}
                                    labelTextColor="text-gray-200 text-left"
                                />
                            </>
                        )}

                        {form.business_area === 'Support Teams' && (
                            <Input
                                name="location"
                                placeholder="What’s your location?"
                                type="text"
                                value={form.location ?? ''}
                                onChange={v => handleInput('location', v)}
                                labelTextColor="text-gray-200 text-left"
                            />
                        )}

                        {/*{(form.business_area === 'Franchise Store') && <>*/}
                        {/*    <SimpleSelect*/}
                        {/*        placeholder="Partner Area"*/}
                        {/*        selected={form.partner_area}*/}
                        {/*        options={partnerArea}*/}
                        {/*        setSelected={v => handleInput('partner_area', v)}*/}
                        {/*    />*/}
                        {/*    <Input*/}
                        {/*        containerClassName="md:col-span-2"*/}
                        {/*        name="partner_company"*/}
                        {/*        placeholder="Partner Company Name"*/}
                        {/*        type="text"*/}
                        {/*        value={form.partner_company}*/}
                        {/*        onChange={v => handleInput('partner_company', v)}*/}
                        {/*        labelTextColor="text-gray-200 text-left"*/}
                        {/*    />*/}
                        {/*</>}*/}

                        <div className="my-2 mx-auto md:col-span-2">
                            <Checkbox
                                labelPlacement="right" value={form.terms_and_conditions} id="terms_and_conditions"
                                onChange={() => handleInput('terms_and_conditions', !form.terms_and_conditions)}
                                containerClassName={"text-black"}
                                label={() => (<>
                                    <span className={"text-black"}>I have read and agree with the <Link className="underline" to="/policies">terms and
                                        conditions</Link></span>
                                </>)}
                            />
                        </div>

                        <div className="flex flex-col md:col-span-2">
                            <div className="mx-auto">

                                <PrimaryButton
                                    fontSize="text-lg"
                                    text={(() => (
                                        <div>
                                            {translation('auth_frontend', 'login')}
                                            <FontAwesomeIcon className="ml-2" icon={faCaretRight} />
                                        </div>
                                    ))()}
                                    working={working}
                                />
                            </div>

                            <div className="mx-auto flex flex-col mt-4">
                                <Link to="/login" colour="text-white mb-1">
                                    Already have an account?
                                </Link>
                                <Link to="/policies" colour="text-white underline">
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </Unauthenticated>
        );
    }
}

export default FormHandler(Register);

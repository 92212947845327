import React from 'react';
import {Link} from "react-router-dom";

import {Loading, Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {FormHandler, Textarea} from 'Components/Form';

import AdminTranslationsApi from 'Services/Api/Admin/Translations';

class TranslationUpdate extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Value updated successfully';

    /**
     * @var state
     */
    state = {
        editMode: false,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        const {group, the_key, language, value} = this.props;

        this.props.setForm({
            group,
            key: the_key,
            language,
            value,
        });
    };

    /**
     * @method handleSubmit
     * @param {Event} e
     */
    handleSubmit = async (e) => {
        const {form, handleSubmit} = this.props;

        await handleSubmit(e, AdminTranslationsApi.update, this.success);

        // Collect alert after handleSubmit() to get the latest value.
        const {alert} = this.props;

        if (alert.type === 'success') {
            this.props.updateValueCallback(form.group, form.key, form.language, form.value);

            this.setState({
                editMode: false,
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {editMode} = this.state;
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        if (!editMode) {
            return (
                <div
                    className={`
                        cursor-pointer w-full h-full
                        ${form.value && form.value !== '' ? 'text-left ': 'text-center'}
                    `}
                    onClick={() => this.setState({editMode: true})}
                >
                    {form.value && form.value !== '' ? form.value : '-'}
                </div>
            );
        }

        return (
            <form
                autoComplete="off"
                className="text-center"
                onSubmit={this.handleSubmit}
            >
                {alert !== null && (<Alert {...alert} />)}

                <div className="my-4">
                    <Textarea
                        id="value"
                        value={form.value}
                        onChange={(v) => handleInput('value', v)}
                        block={false}
                    />
                </div>

                <PrimaryButton
                    text="Save"
                    working={working}
                />
            </form>
        );
    }
}

export default FormHandler(TranslationUpdate);

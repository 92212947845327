import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <div className="flex w-full justify-center space-x-4 text-white py-4 bg-transparent">
            <Link to="/policies/terms">
                Terms and Conditions
            </Link>
            <div>
                -
            </div>
            <Link to="/policies">
                Privacy
            </Link>
            <div>
                -
            </div>
            <Link to="/faq">
                FAQs
            </Link>
        </div>
    );
}

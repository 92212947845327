import React from 'react';

export default function Point(
    {
        position: {top, bottom, left, right}, style, fontSize, padding, children, selected = () => {}
    }
) {
    return (
        <div onMouseDown={selected} className={`absolute leading-tight`} style={{
            top, left, fontSize, bottom, right,
            paddingLeft: padding,
            paddingRight: padding,
            ...style
        }}>
            {children && <div className="cursor-pointer">
                {children}
            </div>}
        </div>
    );
}

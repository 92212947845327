import ApiBase from './ApiBase';

class Leaderboard extends ApiBase {
    /**
     * @method getMe
     * @return {Promise<*>}
     */
    getLeaderboards = (data) => {
        return this.get('leaderboards', data);
    };

    storeLeaderboard = (data) => {
        return this.post('leaderboards', data);
    }

    getPosition = (data) => {
        return this.get('leaderboards/position', data);
    }
}

window.aaaa = new Leaderboard();

export default new Leaderboard();

import {faCaretRight} from '@fortawesome/free-solid-svg-icons/faCaretRight';
import React from 'react';
import Unauthenticated from 'Components/Layouts/Unauthenticated';

import {FormHandler, Input} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Link} from 'Components/Utilities';

import Auth from 'Services/Api/Auth/Auth';
import {asset} from 'Services/BaseHelpers';
import {translation} from 'Services/TranslationHelpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Login extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated width="w-full px-4 text-3xl">
                <div className="text-white text-center max-w-3xl mx-auto">

                    <div className="text-5xl font-costa-display-wave md:text-app-plum mb-3">
                        Log in to play
                    </div>

                    <form autoComplete="off"  className="mt-6" onSubmit={(e) => handleSubmit(e, Auth.login)}>
                        {alert !== null && (<Alert {...alert} />)}

                        <Input
                            containerClassName="mb-4"
                            placeholder={translation('auth_frontend', 'email')}
                            type="email"
                            value={form.email}
                            onChange={v => handleInput('email', v)}
                            id="email"
                        />

                        <Input
                            placeholder={translation('auth_frontend', 'password')}
                            type="password"
                            value={form.password}
                            onChange={v => handleInput('password', v)}
                            id="password"
                        />

                        <div className="text-center mt-8 flex flex-col">
                            <div className="mx-auto mb-6">
                                <PrimaryButton
                                    fontSize="text-lg"
                                    text={(() => (
                                        <div>
                                            {translation('auth_frontend', 'login')}
                                            <FontAwesomeIcon className="ml-2" icon={faCaretRight} />
                                        </div>
                                    ))()}
                                    working={working}
                                />
                            </div>

                            <Link
                                colour="text-white"
                                to="/register"
                            >
                                {translation('auth_frontend', 'register_here')}
                            </Link>

                            <Link
                                colour="text-white"
                                to="/forgot-password"
                            >
                                Forgot password?
                            </Link>
                        </div>
                    </form>
                </div>
            </Unauthenticated>
        );
    }
}

export default FormHandler(Login);

import React from "react";
import Unauthenticated from "Components/Layouts/Unauthenticated";
import Privacy from "Pages/Policies/Privacy";
import Cookies from "Pages/Policies/Cookies";
import Terms from "Pages/Policies/Terms";
import {Link} from "react-router-dom";

export default function Policies({match}) {
    const policy = match.params.policy ?? 'privacy';

    return (
        <Unauthenticated width="">
            <div className="mx-auto flex flex-row mt-6 md:mt-12">
                <Link to="/policies/privacy" className={"bg-app-red rounded-md p-4 text-white mx-auto border-2 " + (policy === 'privacy' ? 'border-app-brown' : 'border-transparent')}>
                    Privacy Policy
                </Link>
                <Link to="/policies/cookie" className={"bg-app-red rounded-md p-4 text-white mx-auto border-2 " + (policy === 'cookie' ? 'border-app-brown' : 'border-transparent')}>
                    Cookie Policy
                </Link>
                <Link to="/policies/terms" className={"bg-app-red rounded-md p-4 text-white mx-auto border-2 " + (policy === 'terms' ? 'border-app-brown' : 'border-transparent')}>
                    Terms & Conditions
                </Link>
            </div>

            {policy !== 'cookie' && policy !== 'terms' && <Privacy />}
            {policy === 'cookie' && <Cookies />}
            {policy === 'terms' && <Terms />}
        </Unauthenticated>
    )
}

import Echo from 'laravel-echo';
window.io = require('socket.io-client');

import {EventEmitter} from 'events';

import {Toast} from 'Services';

class Sockets extends EventEmitter {
    /**
     * @var echo
     * @type {null}
     */
    echo = null;

    /**
     * @var connected
     * @type {boolean}
     */
    connected = false;

    /**
     * @var hasBeenConnected
     * @type {boolean}
     */
    hasBeenConnected = false;

    /**
     * @method getConnection
     * @return {Echo}
     */
    getConnection = () => {
        if (this.echo === null && typeof io !== 'undefined') {
            this.echo = new Echo({
                client: io,
                key: process.env.MIX_ECHO_KEY,
                broadcaster: 'socket.io',
                host: `${process.env.MIX_ECHO_HOST ?? window.location.hostname}`,
                authEndpoint: `/broadcasting/auth`,
            });

            this.echo.connector.socket.on('connect', () => {
                this.handleConnectedChange(true);
            })

            this.echo.connector.socket.on('disconnect', () => {
                this.handleConnectedChange(false);
            })

            // After we first attempt to connect leave it 5 seconds and check if first connected.
            setTimeout(() => this.checkIfConnected(), 5000);
        }

        return this.echo;
    };

    /**
     * @method getConnected
     * @return {boolean}
     */
    getConnected = () => {
        return this.connected;
    }

    /**
     * @method getMessage
     * @param {boolean} disconnected
     * @return {string}
     */
    getMessage = (disconnected) => {
        if (this.connected === false) {
            if (this.hasBeenConnected === true) {
                return 'Disconnected from live updates. Please refresh your browser page to re-connect. If the problem persists please contact support.';
            } else {
                return 'Unable to connect to live updates. Please refresh your browser page to re-attempt to connect. If the problem persists please contact support.';
            }
        } else {
            return 'Live Updates Enabled';
        }
    }

    /**
     * @method checkIfConnected
     */
    checkIfConnected = () => {
        if (!this.echo.connector.socket.connected) {
            this.handleConnectedChange(false);
        } else {
            this.handleConnectedChange(true);
        }
    }

    /**
     * @method handleConnectedChange
     */
    handleConnectedChange = (connected) => {
        if (connected) {
            this.connected = true;
            this.hasBeenConnected = true;
        } else {
            this.connected = false;
            this.displayError();
        }

        this.emit('change');
    }

    /**
     * @method displayError
     */
    displayError = () => {
        if (this.connected === false && process.env.MIX_APP_ENV !== 'local') {
            Toast.error(
                this.getMessage(),
                'Live Updates'
            );
        }
    }
}

export default new Sockets();

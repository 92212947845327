import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton} from 'Components/Button';

import {translation} from 'Services/TranslationHelpers';

export default class Delete extends React.Component {
    /**
     * @method handleDelete
     * @return {Promise<void>}
     */
    handleDelete = async () => {
        await this.props.onDelete();
        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {title, message} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {title ?? translation('misc', 'are_you_sure')}
                </ModalHeader>

                <ModalBody>
                    <p className="text-center">
                        {message ?? translation('misc', 'are_you_sure_delete', {
                            item: (this.props.itemName ?? 'item')
                        })}
                    </p>
                </ModalBody>

                <ModalFooter alignment="center">
                    <PrimaryButton 
                        className="mr-2" 
                        text={translation('misc', 'cancel')} 
                        onClick={this.props.onClose} />

                    <DangerButton 
                        onClick={this.handleDelete} 
                        text={translation('misc', 'continue')} />
                </ModalFooter>
            </Modal>
        )
    }
};

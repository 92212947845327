import React from 'react';
import isEqual from 'lodash/isEqual';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleLeft} from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft';
import {faArrowCircleRight} from '@fortawesome/pro-regular-svg-icons/faArrowCircleRight';
import {Link} from "react-router-dom";
import {asset} from 'Services/BaseHelpers';

export default class Game extends React.Component {
    /**
     * @var points
     * @type {number}
     */
    points = 0;

    jails = 0;

    /**
     * @var initialPlayerHeight
     * @type {number}
     */
    initialPlayerHeight = 438;

    /**
     * @var initialPlayerWidth
     * @type {number}
     */
    initialPlayerWidth = 369;

    /**
     * @var initialIconSize
     * @type {number}
     */
    initialIconSize = 130;

    /**
     * @var maxIcons
     * @type {number}
     */
    maxIcons = 15;

    /**
     * @var state
     */
    state = {
        points: 0,
        iconPoints: [],
        player: {
            height: this.initialPlayerHeight,
            x: 100,
        },
        iconSize: this.initialIconSize,
        interacted: false,
        timer: '00:30',
        timeout: 1000,
        isMobile: false
    };

    /**
     * @var movePlayer
     * @type {null|string}
     */
    movePlayer = null;

    /**
     * @var playerMaxX
     * @type {number}
     */
    playerMaxX = 1502;

    /**
     * @method parent
     * @type {null}
     */
    parent = null;

    /**
     * @var gameLoopInterval
     * @type {null}
     */
    gameLoopInterval = null;

    /**
     * @var objects
     */
    objects = [
        {src: '/images/games/catch-game/TCC_Asset_01.svg', positive: true},
        {src: '/images/games/catch-game/TCC_Asset_02.svg', positive: true},
        {src: '/images/games/catch-game/TCC_Asset_03.svg', positive: true},
        {src: '/images/games/catch-game/TCC_Asset_04.svg', positive: true},
    ];

    /**
     * @var moveSpeed
     * @type {number}
     */
    moveSpeed = 10;

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.setScales();
        this.trackKeyboardEvents();
        this.gameLoop();

        window.addEventListener('resize', this.setScales);
        window.addEventListener('touchstart', this.noHighlight,{passive:false});
        window.addEventListener('mousedown', this.noHighlight,{passive:false});
        this.setState({
            timeout: this.props.size.width <= 960 ? 10000 : 2000,
            isMobile: this.props.size.width <= 960
        });
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScales);
        window.removeEventListener('touchstart', this.noHighlight);
        window.removeEventListener('mousedown', this.noHighlight);

        window.clearInterval(this.gameLoopInterval);
    };

    /**
     * @method componentDidUpdate
     * @param {object} prevProps
     */
    componentDidUpdate = (prevProps) => {

        if (!isEqual(prevProps.size, this.props.size)) {
            this.setScales();
            this.setState({
                timeout: this.props.size.width <= 960 ? 10000 : 2000,
                isMobile: this.props.size.width <= 960,
                moveSpeed: this.props.size.width <= 960 ? 5 : 10
            });
        }
    };

    /**
     * @method gameLoop
     */
    gameLoop = () => {
        const {timeout, fps, isMobile} = this.state;

        this.gameLoopInterval = setInterval(() => {
            const state = this.state;

            if (this.movePlayer === 'left') {
                let x = this.state.player.x - this.moveSpeed;

                if (x <= 0) {
                    x = 0;
                }

                state.player.x = x;
            } else if (this.movePlayer === 'right') {
                let x = this.state.player.x + this.moveSpeed;

                if (x > this.playerMaxX) {
                    x = this.playerMaxX;
                }

                state.player.x = x;
            }

            if (state.iconPoints.length < this.maxIcons) {
                if (this.randomNumber(20) === 1) {
                    state.iconPoints.push(this.addItem(true));
                }

                // if (this.randomNumber(25) === 1) {
                //     state.iconPoints.push(this.addItem(false));
                // }
            }

            state.iconPoints = state.iconPoints.map(p => ({
                ...p,
                y: p.y + p.speed,
            })).filter(p => p.y < this.props.size.height);

            state.iconPoints = this.checkForCollisions(state.iconPoints);

            this.setState(state);
        },  timeout / 40); // 40 FPS
    };

    /**
     * @checkForCollisions
     * @param points
     * @returns {*}
     */
    checkForCollisions = (points) => {
        const playerBox = {
            left: this.state.player.x,
            right: this.state.player.x + this.state.player.width,
            top: this.props.size.height - this.state.player.height,
            bottom: this.props.size.height,
        };

        return points.filter(p => {
            const iconBox = {
                left: p.x,
                right: p.x + this.state.iconSize,
                top: p.y,
                bottom: p.y + this.state.iconSize,
            };

            const collision = !(playerBox.left > iconBox.right ||
                playerBox.right < iconBox.left ||
                playerBox.top > iconBox.bottom ||
                playerBox.bottom < iconBox.top);

            if (collision) {
                this.handleCollision(p);
            }

            return !collision;
        });
    };

    /**
     * @handleCollision
     * @param point
     */
    handleCollision = (point) => {
        if (point.positive) {
            this.points++;
        } else {
            this.points--;
            //this.jails++;

            if (this.points < 0) {
                this.points = 0;
            }
            //this.props.setAdditional(this.jails);
        }

        this.props.setPoints(this.points);
    };

    /**
     * @method randomNumber
     * @param {number} maxNum
     * @return {number}
     */
    randomNumber = (maxNum) => {
        const min = 1;
        const max = Math.floor(maxNum);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    /**
     * @method addItem
     * @param {boolean} positive
     */
    addItem = (positive) => {
        const {iconSize, isMobile} = this.state;
        const {size} = this.props;
        const available = this.objects.filter(o => o.positive === positive);
        const i = this.randomNumber(available.length) - 1;

        // randomise the x
        const x = this.randomNumber(size.width - iconSize);

        let number = isMobile ? 2 : 5;
        const speed = this.randomNumber(number) + 2;

        return {
            ...available[i],
            y: 0,
            x,
            speed,
        };
    };

    /**
     * @method noHighlight
     */
    noHighlight = (e) => {
        e.preventDefault();
    };

    /**
     * @method setScale
     */
    setScales = () => {
        const scaleY = this.props.size.height / 1080;
        const scaleX = this.props.size.width / 1920;

        // Calculate the max X value to prevent players going off the screen
        this.playerMaxX = this.props.size.width - (350 * scaleX);

        this.setState({
            scaleY,
            scaleX,
            player: {
                ...this.state.player,
                height: this.initialPlayerHeight * scaleY,
                width: this.initialPlayerWidth * scaleX,
            },
            iconSize: this.initialIconSize * scaleY,
        });
    };

    /**
     * @method trackKeyboardEvents
     */
    trackKeyboardEvents = () => {
        window.addEventListener('keydown', (e) => {
            if (['a', 'ArrowLeft', 'Left'].indexOf(e.key) !== -1) {
                this.movePlayer = 'left';
            } else if (['d', 'ArrowRight', 'Right'].indexOf(e.key) !== -1) {
                this.movePlayer = 'right';
            }
        });

        window.addEventListener('keyup', (e) => {
            if (['d', 'a', 'ArrowLeft', 'Left', 'ArrowRight', 'Right'].indexOf(e.key) !== -1) {
                this.movePlayer = null;
            }
        });
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <div ref={ref => this.parent = ref} className={
                'rfe w-full h-full bg-no-repeat bg-contain relative overflow-hidden'
            }>
                <div className="bg-games-catch bg-right-bottom bg-no-repeat bg-cover absolute top-0 h-full w-full" />
                    {this.renderScore()}

                    <Link to="/" className="bg-white absolute p-4 rounded-lg text-app-plum text-center"
                        style={{top: 20, left: 20}}>
                        Exit
                    </Link>

                    {this.renderPlayer()}

                    {this.renderIcons()}

                    {this.renderPaddleControls()}
            </div>
        );
    }

    /**
     * @method renderScore
     * @returns {JSX.Element}
     */
    renderScore = () => {
        return (
            <div className="absolute rounded-lg bg-games-catch-timer bg-no-repeat h-96 max-h-[14rem] w-24 lg:w-32 bg-contain right-5 top-5 text-lg text-bt-curve-bold text-app-blue text-center p-4">
                <div className={"relative text-2xl lg:text-4xl font-extra-bold top-10 w-full"}>00:{String(this.props.timeLimit - this.props.currentTime).padStart(2, '0')}</div>
                <div className={"relative text-md lg:text-lg font-extra-bold  top-10 w-full"}>Bubbles: {this.points}</div>
            </div>
        );
    };

    /**
     * renderPlayer
     * @return {JSX.Element}
     */
    renderPlayer = () => {
        const {player} = this.state;

        return (
            <div>
                <img
                    alt=""
                    src={asset("/images/games/catch-game/TCC_Asset_Lady.svg")}
                    className="absolute bottom-0"
                    style={{
                        left: player.x,
                        height: player.height,
                    }} />
            </div>
        );
    };

    /**
     * @method renderIcons
     * @return {JSX.Element}
     */
    renderIcons = () => {
        const {iconPoints, iconSize} = this.state;

        return (
            <React.Fragment>
                {iconPoints.map((p, key) => (
                    <img
                        key={key}
                        src={asset(p.src)}
                        className="absolute p-2"
                        alt=""
                        style={{
                            top: p.y,
                            left: p.x,
                            width: iconSize,
                            height: iconSize,
                        }}
                    />
                ))}
            </React.Fragment>
        );
    };

    /**
     * @method renderPaddleControls
     * @return {JSX.Element}
     */
    renderPaddleControls = () => {
        return (
            <React.Fragment>
                {this.renderPaddleIcon(faArrowCircleLeft, 'left')}
                {this.renderPaddleIcon(faArrowCircleRight, 'right')}
            </React.Fragment>
        );
    };

    /**
     * @method renderPaddleIcon
     * @param icon
     * @param {string} move
     * @return {JSX.Element}
     */
    renderPaddleIcon = (icon, move) => {
        const interacted = this.state.interacted;
        return (
            <div
                className={
                    'rounded-full p-2 text-app-plum flex items-center w-24 h-24 justify-center transition ' +
                    'duration-200 transform cursor-pointer absolute bottom-28 ' +
                    (move === 'right' ? 'right-5' : 'left-5' + (interacted ? '' : ' animate-waiting'))
                }
                onTouchStart={(e) => this.handlePaddleClick(e, move)}
                onMouseDown={(e) => this.handlePaddleClick(e, move)}
                onTouchEnd={this.handlePaddleRelease}
                onMouseUp={this.handlePaddleRelease}
            >
                <FontAwesomeIcon size="4x" icon={icon} />
            </div>
        );
    };

    /**
     * @method handlePaddleClick
     * @param {Event} e
     * @param {string} move
     */
    handlePaddleClick = (e, move) => {
        //e.preventDefault();
        this.movePlayer = move;

        if (!this.state.interacted) {
            this.setState({
                interacted: true,
            })
        }
    };

    /**
     * @method handlePaddleRelease
     */
    handlePaddleRelease = (e) => {
        //e.preventDefault();
        this.movePlayer = null;
    };
}

import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Label from './Label';

/**
 * @function Checkbox
 * @param {string} labelPlacement
 * @param {string} containerClassName
 * @param {string} label
 * @param {string} id
 * @param {function} onChange
 * @param {*} value
 * @param {string} instructions
 * @param {string} [labelColor]
 * @param {boolean} [required]
 * @return {JSX.Element}
 * @constructor
 */
const Checkbox = (
    {
        labelPlacement = 'left', containerClassName, label, id, onChange, value,
        instructions, labelColor = 'text-white', required = true
    }
) => {
    let classes = `
        h-4 w-4 text-app-brown transition duration-150 ease-in-out appearance-none rounded-full
        border-gray-500 border hover:border-gray-600 focus:border-app-brown focus:ring-app-brown
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <div className={`${containerClassName} flex items-center`}>
            {(labelPlacement === 'left') && label && (
                <Label
                    label={label}
                    htmlFor={id}
                    className="mb-0 mr-4"
                    textColor={labelColor}
                    instructions={instructions}
                />
            )}

            <input
                type="checkbox"
                id={id}
                checked={value}
                onChange={onChange}
                className={classes}
                required={required}
            />

            {(labelPlacement === 'right') && label && (
                <Label
                    label={label}
                    htmlFor={id}
                    className="mb-0 ml-4"
                    textColor={labelColor}
                    instructions={instructions}
                />
            )}
        </div>
    )
};

export default Checkbox;

import Dashboard from 'Pages/Dashboard';
import FAQ from 'Pages/FAQ';
import CatchGame from 'Pages/Games/CatchGame';
import SearchGame from 'Pages/Games/SearchGame';
import Policies from "Pages/Policies";
import GameOver from "Pages/GameOver";

export default [
    {path: '/', component: Dashboard},
    {path: '/game-over', component: GameOver},
    {path: '/games/catch-game', component: CatchGame},
    {path: '/games/search-game', component: SearchGame},
    {path: '/faq', component: FAQ},
    {path: '/policies/:policy?', component: Policies}
];

import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown';
import {Listbox, Transition} from '@headlessui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SimpleSelect(
    {
        selected, options, setSelected, label, placeholder
    }
) {
    return (
        <Listbox value={selected} onChange={setSelected}>
            {({open}) => (
                <div>
                    {label && (
                        <Listbox.Label className="block font-medium text-gray-700 mb-1">
                            {label}
                        </Listbox.Label>
                    )}
                    <div className="relative">
                        <Listbox.Button
                            className="relative w-full border border-white border-opacity-60 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-app-brown focus:border-app-brown">
                            <span className="block truncate text-center">
                                {selected && typeof selected === 'object' && selected.label}
                                {selected && typeof selected === 'string' && selected}
                                {!selected && (
                                    <span className="text-white text-opacity-60">
                                        {placeholder && placeholder}
                                        {selected && 'Select...'}
                                    </span>
                                )}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <FontAwesomeIcon icon={faCaretDown} className="h-5 w-5 text-white" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            enter="transition-opacity duration-400"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                static
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
                            >
                                {options.map((option) => (
                                    <Listbox.Option
                                        key={option.id}
                                        className={({active}) =>
                                            classNames(
                                                active ? 'text-white bg-app-brown' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9',
                                            )
                                        }
                                        value={option}
                                    >
                                        {({selected, active}) => (
                                            <>
                                                <span
                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {option && typeof option === 'object' && option.label}
                                                    {option && typeof option === 'string' && option}
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}

import React from 'react';

export default class ResponsiveContainer extends React.Component {
    /**
     * @var state
     * @type {{size: {width: number, height: number}}}
     */
    state = {
        size: {
            width: 1920,
            height: 1080
        },
        previousElement: ''
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.setStyles();

        window.addEventListener('resize', this.setStyles);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setStyles);
    };

    /**
     * @method setStyles
     */
    setStyles = () => {
        // Fix for android, keyboard was causing resize event to trigger.
        if (document.activeElement.tagName === 'INPUT') {
            return;
        }

        if (this.props.mode === 'portrait') {
            this.setPortraitStyles();
        } else if (this.props.mode === 'landscape') {
            this.setLandscapeStyles();
        }
    };

    /**
     * @method setPortraitStyles
     */
    setPortraitStyles = () => {
        let height = window.innerHeight > 1080 ? 1080 : window.innerHeight;
        let width = height * 0.5625;

        if (width > window.innerWidth) {
            width = window.innerWidth;
            width = height * 1.7777;
        }

        this.setState({
            size: {
                width: Math.max(width, this.props.maxWidth ?? 0),
                height,
            },
        });
    };

    /**
     * @method setLandscapeStyles
     */
    setLandscapeStyles = () => {
        let width = window.innerWidth > 1920 ? 1920 : window.innerWidth;
        let height = width * 0.5625;

        if (height > window.innerHeight) {
            height = window.innerHeight;
            width = height * 1.7777;
        }

        this.setState({
            size: {
                width: Math.max(width, this.props.minWidth ?? 0),
                height,
            }
        });
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {size} = this.state;
        const {children: Children} = this.props;

        return (
            <div style={size} id="responsive-container" className="my-auto">
                <Children size={size} />
            </div>
        );
    }
}

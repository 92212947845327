import Unauthenticated from 'Components/Layouts/Unauthenticated';
import {DateTime} from 'luxon';
import React, {Component} from 'react';
import {asset} from 'Services/BaseHelpers';

export default class Closed extends Component {
    render() {
        return (
            <Unauthenticated width="max-w-3xl w-full px-4 py-8 lg:py-12" header={false}>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 text-white text-center">
                    <img className="w-1/3 mx-auto mb-8 lg:mb-24" src={asset('/images/logo.png')} alt="Costa" />

                    <div className="text-2xl mt-5 mb-2">
                        The Festive Costa Challenge minigame is now closed. But we're bringing you plenty more chances
                        to win with the 12 Days of Christmas, so keep an eye out for more to come.
                    </div>

                </div>
            </Unauthenticated>
        );
    }
}

import React from 'react';
import {Link as DomLink} from 'react-router-dom';

/**
 * @function Link
 * @param {string} to
 * @param children
 * @param {string} colour
 * @param {boolean} underline
 * @return {JSX.Element}
 * @constructor
 */
const Link = ({to, children, colour, underline = false}) => {
    return (
        <DomLink to={to} className={`text-sm ${colour ?? 'text-white'} ${underline ? 'underline' : ''}`}>
            {children}
        </DomLink>
    );
};

export default Link;

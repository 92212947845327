import React from 'react';
import Unauthenticated from 'Components/Layouts/Unauthenticated';

import {Card, CardBody, CardFooter, CardHeader} from 'Components/Card';
import {FormHandler, Input} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Link} from 'Components/Utilities';

import Auth from 'Services/Api/Auth/Auth';
import {asset} from 'Services/BaseHelpers';
import {translation} from 'Services/TranslationHelpers';

class Login extends React.Component {
    success = 'You should receive an email shortly.';

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated width="max-w-xl w-full px-4 text-3xl">
                <div className="text-white text-center">
                    <img className="w-1/3 mx-auto mb-8 lg:mb-12" src={asset("/images/logo.png")} alt="Costa"/>

                    <div className="text-3xl text-app-blue mb-3 font-bold uppercase">
                        Forgot password?
                    </div>

                    <form autoComplete="off"  className="mt-4" onSubmit={(e) => {
                        handleSubmit(e, Auth.forgotPassword, this.success)}
                    }>
                        {alert !== null && (<Alert {...alert} />)}

                        <Input
                            containerClassName="mb-4"
                            placeholder={translation('auth_frontend', 'email')}
                            type="email"
                            value={form.email}
                            onChange={v => handleInput('email', v)}
                        />

                        <div className="text-center mt-4 flex flex-col">
                            <div className="mx-auto mb-2">
                                <PrimaryButton
                                    text="Request password"
                                    working={working}
                                />
                            </div>
                            <Link
                                colour="text-white"
                                to="/login"
                            >
                                Back
                            </Link>
                        </div>
                    </form>
                </div>
            </Unauthenticated>
        );
    }
}

export default FormHandler(Login);

import React from 'react';

/**
 * @function PlayGameButton
 * @param {function} handleGoToScreen
 * @param {string} className
 * @param {string} title
 * @return {JSX.Element}
 * @constructor
 */
const PlayGameButton = ({handleGoToScreen, className,  title = 'Play Game'}) => {
    return (
        <button
            className={
                'px-2 sm:px-2 mb-8 py-1 sm:py-1 g:px-4 lg:py-2 rounded-md bg-white text-app-plum text-md ' +
                'lg:text-xl bg-white transition duration-200 transform hover:scale-110 ' +
                'font-bt-curve-bold' +
                className
            }
            onClick={() => handleGoToScreen('game')}>
            {title}
        </button>
    );
};

export default PlayGameButton;

import Settings from 'Services/Settings';

/**
 * @method translationFromJson
 * @param {object} data
 * @return {string}
 */
const translationFromJson = (data) => {
    let translation = '';

    if (!data || !data[Settings.data.language]) {
        console.error(
            "No translation value found in json data for user's language"
        );
    } else {
        translation = data[Settings.data.language];
    }

    return translation;
}

export {
    translationFromJson
}

import React from 'react';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Label from 'Components/Form/Label';

import Settings from 'Services/Settings';
import {isAdminPage} from 'Services/BaseHelpers';

/*
Examples

Date Only ...
<DatePicker
    label="My Date"
    selected={form.my_date}
    onChange={date => handleInput('my_date', date)}
/>

Date & Time ...
<DatePicker 
    label="My Date & Time"
    selected={form.my_date_time} 
    onChange={date => handleInput('my_date_time', date)}
    showTimeSelect={true}
    timeIntervals={5}
/>

Time Only ...
<DatePicker 
    label="My Time"
    selected={form.my_time} 
    onChange={date => handleInput('my_time', date)}
    showTimeSelect={true}
    showTimeSelectOnly
    timeIntervals={5}
/>
*/

/**
 * @param {string} containerClassName
 * @param {string} label
 * @param {string} id
 * @param {string} className
 * @param {object} selected
 * @param {object} onChange
 * @param {boolean} nowButton
 * @param {boolean} showTimeSelect
 * @param {string} instructions
 * @param {*[]} rest
 * @return {JSX.Element}
 */
export default ({containerClassName, label, id, className, selected, onChange, nowButton = false, showTimeSelect = false, instructions, ...rest}) => {
    let timezone = Settings.data.timezone;

    if (showTimeSelect) {
        label += " (" + timezone + ")";
    }

    let classes = `
        w-full border border-gray-300 ${className}
        ${nowButton ? 'rounded-l-md' : 'rounded-md'}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }
    
    if (selected) {
        if (showTimeSelect) {
            // Convert to user's selected timezone to display the correct time.
            selected = utcToZonedTime(selected, timezone);
        } else {
            // Offset the device timezone to maintain the correct date.
            selected = new Date(selected.getTime() + (selected.getTimezoneOffset()*60*1000));
        }
    }

    return (
        <div className={`w-full ${containerClassName}`}>
            {label && (<Label label={label} htmlFor={id} instructions={instructions} />)}

            <div className="flex mt-1 shadow-sm">
                <DatePicker
                    {...rest}
                    showTimeSelect={showTimeSelect}
                    dateFormat={showTimeSelect ? 'MMMM do yyyy, HH:mm' : 'MMMM do yyyy'}
                    className={classes}
                    selected={selected}
                    onChange={(v, e) => {
                        if (!v) {
                           onChange(null);
                        }

                        if (showTimeSelect) {
                            // Convert from user's selected timezone to save the correct time.
                            onChange(zonedTimeToUtc(v, timezone));
                        } else {
                            // Offset the device timezone to maintain the correct date.
                            onChange(new Date(
                                new Date(v.getTime() - (v.getTimezoneOffset()*60*1000))
                                    .setUTCHours(0, 0, 0, 0)
                            ));
                        }
                    }}
                />

                {nowButton &&
                    <button
                        type="button"
                        onClick={() => onChange(new Date())}
                        className={
                            `-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border
                            border-gray-300 text-sm font-medium rounded-r-md bg-gray-50
                            hover:bg-gray-100 transition duration-200 focus:ring-0
                            text-gray-700`
                        }>
                        Now
                    </button>
                }
            </div>
        </div>
    );
};

import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Label from './Label';

/**
 * @function Textarea
 * @param {string} containerClassName
 * @param {string} className
 * @param {string} label
 * @param {string} placeholder
 * @param {string} id
 * @param {function} onChange
 * @param {string} value
 * @param {boolean} block
 * @param {string} instructions
 * @param {*} value
 * @return {JSX.Element}
 * @constructor
 */
const Textarea = ({containerClassName, className, label, placeholder, id, onChange, value, block = true, instructions}) => {
    let classes = `
        mt-1 ${block ? 'block w-full': 'min-w-full'} border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none
        focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <div className={containerClassName}>
            {label && (<Label label={label} htmlFor={id} instructions={instructions} />)}

            <textarea
                id={id}
                value={value ?? ''}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                className={classes}
            />
        </div>
    )
};

export default Textarea;

import { useEffect, useState } from 'react';



import { asset } from 'Services/BaseHelpers';

const Rotator = ({handleChange}) => {
    const [askToRotate, setAskToRotate] = useState(false);

    useEffect(() => {
        const rotationCheck = () => {
            setAskToRotate(window.innerHeight > window.innerWidth);
        };

        rotationCheck();

        window.addEventListener('resize', rotationCheck);

        handleChange(event, rotationCheck);


        return () => {
            window.removeEventListener('resize', rotationCheck);
        };
    }, []);

    if (!askToRotate) {

        return null;
    }

    return (
        <div className="absolute top-0 left-0 right-0 w-screen h-screen z-50 bg-app-plum flex items-center justify-center flex-col text-center">

            <div className="text-4xl text-app-yellow">
                BEFORE WE BEGIN
            </div>

            <div className="text-xl text-white">
                Rotate your handset to landscape
            </div>

            <img src={asset('/images/rotate-device.png')} className="mt-10 h-16 " />
        </div>
    );
};

export default Rotator;

import {EventEmitter} from 'events';

class Cookies extends EventEmitter {
    /** @property {'accept'|'denied'|null} cookie */
    cookie = null;

    constructor(props) {
        super(props);

        this.cookie = localStorage.getItem('cookie');
    }

    setCookie = (cookie) => {
        this.cookie = cookie;
        localStorage.setItem('cookie', cookie);

        this.emit('update');
    }
}

export default new Cookies();

import React from 'react';
import reactStringReplace from 'react-string-replace';

/**
 * @method translation
 * @param {string} group
 * @param {string} key
 * @param {object} data
 * @return {string}
 */
const translation = (group, key, data = null) => {
    let translation = '';

    if (!window.base.translations[group] || !window.base.translations[group][key]) {
        console.error(
            'No translation value found for group: ' + group + ' and key: ' + key
        );
    } else {
        translation = window.base.translations[group][key];
    }

    if (translation && data) {
        translation = translationData(translation, data);
    }

    if (translation) {
        translation = handleNewLines(translation);
    }

    return translation;
}

/**
 * @method translationData
 * @param {string} translation
 * @param {object} data
 * @return {string}
 */
const translationData = (translation, data) => {
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            let placeholder = ':' + key.toUpperCase();
            let value = data[key];

            translation = reactStringReplace(translation, placeholder, (match, i) => (value));
        }
    }

    return translation;
}

/**
 * @method handleNewLines
 * @param {mixed} translation
 * @return {mixed}
 */
const handleNewLines = (translation) => {
    if (typeof translation === 'string' || translation instanceof String) {
        return handleNewLines2(translation);
    } else {
        return Object.entries(translation).map((holder, key) => {
            return handleNewLines2(holder[1]);
        });
    }
}

/**
 * @method handleNewLines2
 * @param {mixed} value
 * @return {mixed}
 */
const handleNewLines2 = (value) => {
    if (typeof value === 'string' || value instanceof String) {
        if (value.includes("\n")) {
            value = value.split("\n").map((i, key) => {
                let className = (key === 0 ? '' : 'mt-2');

                return <>
                    <p className="mt-2" />
                    {i}
                </>;
            });
        }
    }

    return value;
}

export {
    translation
}

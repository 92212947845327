import {PlayGameButton} from 'Components/Games/Partials';
import {GameBackground, ResponsiveContainer} from 'Components/Partials';
import {DateTime} from 'luxon';
import React from 'react';
import Base from 'Components/Games/Base';
import {Link, Redirect} from 'react-router-dom';
import Game from './Game';
import {User} from "Services";

export default class SearchGame extends Base {
    /**
     * @var gameName
     * @type {string}
     */
    gameName = 'search-game';

    game = {
        method: 'component',
        component: Game
    };

    /**
     * @method render
     * @returns {JSX.Element}
     */
    render() {
        const {stage} = this.state;

        if (stage === 'game') {
            return (
                <GameBackground screen="game">
                    {this.renderGame()}
                </GameBackground>
            );
        }

        return (
            <GameBackground screen="game">
                <ResponsiveContainer mode={'landscape'}>
                    {() => (
                        <React.Fragment>
                            {stage === 'instructions' && this.renderInstructions()}
                            {stage === 'times-up' && this.renderTimesUp()}
                        </React.Fragment>
                    )}
                </ResponsiveContainer>
            </GameBackground>
        );
    }

    /**
     * @method renderWelcome
     * @return {JSX.Element}
     */
    renderInstructions = () => {
        return (
            <div
                className="bg-ready-for-everything w-full h-full bg-no-repeat bg-cover relative flex overflow-y-auto">
                <div className="my-auto w-full">
                    <div className="my-4 lg:mt-8 w-full">
                        <div className="text-center">
                            <div className={
                                'text-white font-bold md:text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl lg:mb-4 ' +
                                'xl:mb-6 2xl:mb-12 font-bt-curve-bold'
                            }>
                                Hunt down the elves
                            </div>
                            <div className={
                                'text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl ' +
                                '2xl:text-2xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold'
                            }>
                                Grab a magnifying glass, it's time to find 25 elves as quickly as you can.
                            </div>
                            <div className={
                                'text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl ' +
                                '2xl:text-2xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold'
                            }>
                                Are you ready for the challenge?
                            </div>

                            <PlayGameButton handleGoToScreen={() => this.handleGoToStage('game')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderPoints = (points) => {
        const time = DateTime.fromMillis(points).toUTC().toFormat('mm:ss:SSS');
        return time.substring(0, time.length - 1);
    }

    /**
     * @method renderTimesUp
     * @return {JSX.Element}
     */
    renderTimesUp = () => {
        return (
            <div
                className="bg-ready-for-everything w-full h-full bg-no-repeat bg-cover relative flex overflow-y-auto">
                <div className="my-auto w-full">
                    <div className="my-4 lg:mt-8 w-full">
                        <div className="text-center">
                            <div
                                className="text-white font-bold md:text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold">
                                Nice try!
                            </div>

                            <div
                                className="text-white mb-2 sm:mb-5 text-xs sm:text-sm sm:text-base lg:text-lg xl:text-xl 2xl:text-2xl lg:mb-4 xl:mb-6 2xl:mb-12 font-bt-curve-bold max-w-4xl mx-auto">
                                {this.points > 7000 && <>
                                    You completed the game in {this.renderPoints(this.points)}!{' '}
                                    {!User.data.winner && <>
                                        Head to the leaderboard to see if you are a Costa Challenge Champ.
                                    </>}
                                    {User.data.winner && <>
                                        Thanks for playing - you were a previous week winner so your score wont go onto
                                        this week's leaderboard.
                                    </>}
                                </>}
                                {this.points <= 7000 && <>
                                    WOW - this seems like a super human score so we can't count this in the leaderboard!
                                    Have another go or contact <a className="underline"
                                                                  href="mailto:demo@demo.com">demo@demo.com</a> if
                                    you think this is incorrect.
                                </>}
                            </div>

                            <Link to="/" className={
                                'px-2 sm:px-4 py-1 sm:py-2 lg:px-8 lg:py-4 rounded-md bg-app-brown text-xl ' +
                                'lg:text-3xl text-white transition duration-200 transform hover:scale-110 ' +
                                'font-bt-curve-bold'
                            }>
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

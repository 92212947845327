import React from 'react';
import { Link } from 'react-router-dom';
import Header from 'Components/Header';
import {asset} from 'Services/BaseHelpers';

export default () => (
    <div className="flex flex-col">
        <Header/>
        <div className="max-w-4xl min-w-screen-sm w-full px-4 mx-auto">
            <img alt="Leaderboard"
                 className="w-2/3 mx-auto mt-6 lg:mt-12 2xl:mt-16" src={asset("/images/Closed Challenge.png")}/>

            <div className="text-center space-y-6 text-xl text-white my-6">
                <p>
                    THIS COMPETITION HAS NOW CLOSED!
                </p>

                <p>
                    Thanks for playing
                </p>
            </div>
        </div>
    </div>
);

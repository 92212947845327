import React from 'react';

/**
 * @function GameWindow
 * @param {string} title
 * @param {string} body
 * @param {function} onClick
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const GameWindow = ({title, body, onClick, className}) => {
    return (
        <div className={`bg-white ${className ?? 'w-10/12 md:w-2/3 lg:w-3/5'} p-6 text-center ${className}`}>
            <h3 className="text-xl lg:text-3xl text-purple mb-4 font-bt-curve-bold">{title}</h3>

            <div className="text-gray-800 text-md lg:text-lg leading-5">{body}</div>
        </div>
    );
}

export default GameWindow;

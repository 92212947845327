import React from 'react';
import {GameBackground, GameWindow, ResponsiveContainer} from 'Components/Partials';
import Leaderboard from 'Services/Api/Leaderboard';
import Rotator from "Components/Rotator";

export default class Base extends React.Component {
    /**
     * @var state
     * @type {{stage: string}}
     */
    state = {
        stage: 'instructions',
        points: 0,
        showGame: false
    };

    /**
     * @var iframeRef
     * @type {null}
     */
    iframeRef = null;

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        window.addEventListener('message', this.handleMessage);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        window.removeEventListener('message', this.handleMessage);
    };

    /**
     * @method handleMessage
     * @param e
     */
    handleMessage = (e) => {
        if (e.data === `${this.gameName}-complete`) {
            this.props.onComplete();
            this.handleGoToStage('clue');
        } else if (e.data === `${this.gameName}-times-up`) {
            this.props.onComplete();
            this.handleGoToStage('times-up');
        } else if (e.data === `${this.gameName}-incorrect`) {
            this.props.onComplete();
            this.handleGoToStage('incorrect');
        }
    };

    /**
     * @method handleGoToStage
     * @param {string} stage
     */
    handleGoToStage = (stage) => {
        this.setState({
            stage
        });
    };
    handleChange = (event, showGame) => {
        this.setState({
            showGame: showGame
        });
    };
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render() {
        const {stage, showGame} = this.state;

        return (
            <GameBackground screen="game">
                <Rotator   handleChange={this.handleChange}/>
                { showGame && <ResponsiveContainer mode={ 'landscape' }>
                    { ( { size } ) => (
                        <React.Fragment>
                            { stage === 'instructions' && this.renderInstructions () }
                            { stage === 'game' && this.renderGame ( size ) }
                            { stage === 'times-up' && this.renderTimesUp () }
                        </React.Fragment>
                    ) }
                </ResponsiveContainer>
                }
            </GameBackground>
        );
    }

    /**
     * @method renderWelcome
     * @return {JSX.Element}
     */
    renderWelcome = () => {
        return (
            <GameWindow
                onClick={() => this.handleGoToStage('game')}
                title={this.instructions.title}
                body={this.instructions.body}
            />
        );
    };

    setPoints = (points) => {
        this.points = points;
    };

    setAdditional = (additional) => {
        this.additional = additional;
    };

    /**
     * @method renderGame
     * @return {JSX.Element}
     */
    renderGame = (size) => {
        return (
            <React.Fragment>
                <div className="w-full h-full">
                    {this.game.method === 'iframe' && (
                        <iframe
                            ref={ref => this.iframeRef = ref}
                            src={this.game.src}
                            className="w-screen h-screen"
                        />
                    )}

                    {this.game.method === 'component' && this.renderComponent(size)}
                </div>
            </React.Fragment>
        );
    };

    submitScore = async () => {
        return await Leaderboard.storeLeaderboard({
            points: this.points,
            additional: this.additional,
        });
    }

    /**
     * @method renderComponent
     * @return {JSX.Element}
     */
    renderComponent = (size) => {
        const {component: Component} = this.game;

        return (
            <Component
                size={size}
                handleTimesUp={async () => {
                    this.handleGoToStage('times-up');
                    await this.submitScore();
                }}
                setPoints={this.setPoints}
                setAdditional={this.setAdditional}
            />
        );
    };
}

import Unauthenticated from 'Components/Layouts/Unauthenticated';
import React from 'react';
import {Link} from 'react-router-dom';

const questions = [
    {
        question: 'I’ve registered but can’t log in',
        answer: (() => (<>
            You will only need to register once. You can login again using your email address and the password:
            <span className="bold"> Summer2023</span>.  If you registered for any previous Costa Challenges,
            you would need to register again.
        </>))(),
    },
    {
        question: 'Can I play more than once?',
        answer: 'Yes! You can complete the games as many times as you like, and we’ll take your top score.',
    },
    {
        question: 'What is the prize?',
        answer: "The Top 20 on the leaderboard each week will receive a Costa Goodie bag. Participants ranking between 21-50 each week will receive a Costa Coffee branded flamingo drinks holder..",
    },
    {
        question: 'When will I know if I have won and when will I receive my prize?',
        answer: 'We’ll be in touch just after the leaderboard closes each week and the prize will be sent within 40 days of the end of the Competition.',
    },
    {
        question: 'Will I still get my prize if I leave Costa?',
        answer: 'Unfortunately, if you leave Costa the prize will go to the next most-successful person on the ' +
            'leaderboard.',
    },
    {
        question: 'Can my friends and family play?',
        answer: 'We know the game is addictive, but it’s exclusive to people that fly the Costa flag around the world',
    },
    {
        question: 'Who can I contact with any questions?',
        answer: (() => (<>
            Drop an email to {" "}
            <a
                href="mailto:internalcommunications@costacoffee.com"
                target="_self"
                rel="noopener noreferrer"
                className="text-app-yellow md:text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
            >internalcommunications@costacoffee.com
            </a>
        </>))(),
    },
];

function showQuestion({question, answer}, k) {
    return (
        <div className="pt-6" key={k}>
            <dt className="text-lg">
                <div className="text-left w-full">
                    <span className="font-bold text-white md:ttext-black ">
                        {question}
                    </span>
                </div>
            </dt>
            <dd className="mt-2 pr-12" id="faq-0">
                <p className="text-base text-white md:text-app-plum">
                    {answer}
                </p>
            </dd>
        </div>
    );
}

export default function FAQ() {
    return (
        <React.Fragment>
            <Unauthenticated width="max-w-2xl w-full px-4 py-2 lg:py-12">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:py-2 sm:px-6 lg:px-8">
                    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-100">
                        <h2 className="text-center text-5xl font-costa-display-wave  text-white md:text-app-plum sm:text-4xl">
                            Frequently asked questions
                        </h2>
                        <dl className="mt-6 space-y-6 divide-y divide-app-plum">
                            {questions.map(showQuestion)}
                        </dl>
                    </div>

                    <div className="mt-8">
                        <Link to="/" className="bg-white rounded-md p-3 md:text-app-plum">
                            Go Back
                        </Link>
                    </div>
                </div>
            </Unauthenticated>
        </React.Fragment>
    );
}

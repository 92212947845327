import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';

import Authenticated from 'Components/Layouts/Authenticated';

import * as Admin from './Config';

/**
 * @function AdminLayout
 * @param {JSX.Element} children
 * @return {JSX.Element}
 * @constructor
 */
const AdminLayout = ({children}) => {
    return (
        <Authenticated pageTitle="Admin">
            <main className="relative -mt-32">
                <div className="max-w-screen-xl mx-auto pb-6 lg:pb-16">
                    <div className="bg-white rounded-lg shadow overflow-hidden">
                        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-3">
                                <nav>
                                    {Object.entries(Admin).map((admin2, i) => {
                                        return admin2[1].map((admin3, j) => {
                                            return (
                                                <AdminLayoutItem
                                                    item={admin3}
                                                    key={i+'_'+j}
                                                />
                                            );
                                        });
                                    })}
                                </nav>
                            </aside>

                            <div className="lg:col-span-9">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Authenticated>
    );
};

class AdminLayoutItem extends React.Component {
    /**
     * @var state
     * @type {{dropdown: boolean}}
     */
    state = {
        dropdown: false,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        const {sub} = this.props.item;

        // If one of the sub items is active then default to
        // the menu open.
        if (sub) {
            let dropdown = false;

            sub.map((item, i) => {
                if (item.to === location.pathname) {
                    dropdown = true;
                }
            });

            this.setState({dropdown});
        }
    };

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {dropdown} = this.state;
        const {feature, to, icon, title, sub} = this.props.item;

        let className1 = `group mt-1 border-l-4 border-transparent px-3 py-2 flex items-center
            text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900`;

        let className2 = `flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-teal-500 group-hover:text-teal-500`;

        if (feature && !window.base.features[feature]) {
            return null;
        }

        if (sub) {
            return (
                <div>
                    <div
                        className={`${className1} cursor-pointer`}
                        onClick={() => this.setState({dropdown: !dropdown})}
                    >
                        <FontAwesomeIcon
                            icon={icon}
                            className={className2}
                        />

                        <span className="truncate">
                            {title}
                        </span>

                        <FontAwesomeIcon icon={dropdown ? faCaretUp : faCaretDown} className="ml-2" />
                    </div>

                    {dropdown && 
                        <div className="ml-4">
                            {sub.map((item, i) => (
                                <AdminLayoutItem
                                    item={item}
                                    key={i}
                                />
                            ))}
                        </div>
                    }
                </div>
            );
        }

        return (
            <NavLink
                to={to}
                className={className1}
                activeClassName="bg-teal-50 border-l-4 border-teal-500"
            >
                <FontAwesomeIcon
                    icon={icon}
                    className={className2}
                />

                <span className="truncate">
                    {title}
                </span>
            </NavLink>
        );
    }
}

export default AdminLayout;


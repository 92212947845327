import { Link } from 'react-router-dom';

export default function Cookies() {
    return (
        <main
            className="block my-6 mx-auto leading-7 text-gray-800 md:my-12 max-w-4xl"
        >
            <article className="my-0 text-gray-800 lg:my-0 md:my-0">
                <div
                    className="overflow-hidden my-12 text-base leading-normal bg-gray-200 p-6 md:text-lg"
                >
                    <h2
                        className="mx-0 mt-0 mb-10 text-3xl leading-9 text-red-900 md:text-6xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        Cookie policy
                    </h2>
                    <h6
                        className="mx-0 mt-0 mb-10 text-lg leading-6 text-red-900 md:text-2xl md:leading-7 md:mx-0 md:mt-0 md:mb-8"
                    >
                        July 2021
                    </h6>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Costa Limited respects your data and your privacy is important to us.
                        For more information about how we use and protect data please see our
                        <a
                            href="https://www.costa.co.uk/privacy-policy/"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >Privacy Notice</a>.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>What is a cookie and what do they do? </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        A cookie is a small text file which sits on your device or browser and
                        assists with information flow and functionality. We use cookies to
                        support the main and mobile websites and our app. For instance, some
                        cookies help with site security or provide information to help us
                        improve the site, but each cookie performs a specific action. These
                        cookies don’t usually capture your personal data and we wouldn’t be able
                        to identify people from the information. They simply provide grouped
                        information about site or app navigation which can give you a more
                        personalised experience and tells us if people can find what they want.

                        For more information about how we use and protect data please see our
                        <a
                            href="https://www.costa.co.uk/cookie-policy/"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >Cookie Notice</a>.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong
                        >Session and persistent cookies and how long they last
                        </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        All cookies are either session cookies or persistent cookies.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"><strong className="italic">Session cookies</strong></em>
                        last for the length of your visit to a website and remember information
                        from one page to another, so you don’t have to keep entering it .
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        >They delete themselves when you close your browser.
                        </em>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        ><strong className="italic">Persistent cookies </strong></em
                        >remember things for longer, from one visit to the next. They can
                        personalise the site and remember information each time you visit. We
                        use them to see how people engage with our site to help us change and
                        develop the site and improve your experience of it.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        >These stay on your device when you close your browser and vary by
                            cookie – please see the cookie table at the end for specific
                            expiration times .</em
                        >
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>What are first and third party cookies? </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Whether a cookie is first or third party depends on where it comes from
                        and can be sent by different parties.
                        <br/>
                        <br/>If the cookie comes from our site it is a first party
                        cookie set by us.
                        <br/>
                        <br/>If it comes from another site or organisation, such as
                        our suppliers or business partners, it is a third-party cookie,
                        specifically chosen by us to provide a service, such as a more
                        personalised visit. For example, instance, you may be on a website that
                        has ‘Like’ and ‘Share’ buttons for social network sites. If you click
                        one, the social network site will put a ‘third party’ cookie on your
                        device. That cookie may then collect data about you and send it to them
                        too. You will need to set your privacy choices on their site, to tell
                        them how they can use your data.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong
                        >What different types of cookies does the site use and what are they
                            each for?</strong
                        >
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Different types of cookies do different things. We use the following
                        cookies on our sites and apps.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        ><strong className="italic">Strictly Necessary Cookies</strong></em
                        ><em className="italic"> </em>- these are required for the operation,
                        security and integrity of our website and app and are essential when you
                        move around them and use their features. For example, they remember your
                        details when you login and allow you to access your account. Without
                        such cookies such services cannot be provided.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        ><strong className="italic">Functional Cookies</strong></em
                        >
                        remember you when you return to the site or app and choices you have
                        made on previous visits, such as searches and which country you’re from.
                        This allows us to improve your experience by remembering your
                        preferences when you log into our loyalty club. These Cookies will
                        remain on your device until you choose to clear them, however, if you
                        choose to do this, you will need to log in again into your loyalty
                        account each time you visit the site as an example, but they do
                        eventually expire.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        ><strong className="italic">Analytical/Performance Cookies</strong></em
                        >
                        – these allow us to anonymously count the number of visitors to our
                        website or app and see how they move around and use them. This helps us
                        improve how they work, to find things easily and improve site
                        navigation. The data is aggregated and anonymised, which means we cannot
                        identify you as an individual. Some of the Analytical Cookies we use
                        include (but not limited to) Google Analytics and Adobe Digital
                        Analytics.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Marketing / Targeting cookies </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        These Cookies will collect information about your browsing habits and
                        help website owners to understand the performance of their marketing
                        activities and improve the relevance of the adverts that you see. They
                        are set by the website owner or by carefully selected third parties who
                        may use that information to show you adverts that they think you will be
                        most interested in when you visit other websites They may also use that
                        information to see how well their adverts are performing and that
                        information may also be shared with other parties such as advertisers.
                        If you do not allow these cookies, you may see more content and adverts
                        that do not match your interests.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Costa do not currently use these type of cookies.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>What are similar technologies? </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Tagging works in a similar way to cookies. We use a tag called a pixel.
                        A pixel is an image that once imbedded in an email connects to a file
                        stored on our web server and allows us to determine customer interest in
                        our marketing emails. Tags can also be known as beacons.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        These allow us to see which promotions seem of interest to our
                        customers, and if a particular offer seems to interest you. We may use
                        this information to send offers which appear to be more relevant to you.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Do I Have to Accept Cookies?</strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You may reject cookies, however, site and app functions will be
                        affected. Cookies help our sites and app to work properly and
                        personalise your experience. You’ll be able to browse the sites and app
                        without cookies but some standard functionality, preferences and certain
                        features will not work.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        By accepting cookies, you allow us to improve your experience and
                        remember information about you, which can personalise your experience.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        To withdraw consent, you can reject or delete cookies. Please see
                        the&nbsp;<a
                        href="#managing-your-cookies"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Managing Your Cookies</a
                    >&nbsp;section below on how you can adjust your browser settings.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Consent</strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        By browsing or using our site or app you agree that we and the selected
                        third parties in this notice can use (as described in this notice) data
                        collected by the cookies you allow. If you do not agree, you can
                        withdraw your consent by setting your browser to reject cookies, see
                        below.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Managing Your Cookies</strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        There are several ways to manage cookies. You can:
                    </p>
                    <ol className="pl-10">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Set your browser to prevent cookies from being accepted. More
                                information should be in your browser’s “help” menu. How to adjust
                                your browser will depend on which browser you’re using.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Set some browsers to send you an alert when a website is trying to
                                place a cookie on your browser.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Block cookies by activating the setting on your browser to refuse
                                all or some cookies. However, if you use your browser settings to
                                block all cookies including strictly necessary cookies, site access
                                and features can be limited or not available.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Delete cookies stored in your browser by “clearing cookies”. This
                                will only delete cookies already stored. It won’t prevent new ones
                                being accepted unless you change the acceptance settings. Also,
                                clearing your cookies on one browser of one device does not
                                automatically clear them on another. You need to clear all browsers
                                on all devices, independently.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                By declining cookies in the banner that appears when you visit our
                                site.
                            </p>
                        </li>
                    </ol>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        More information about cookies is available on external websites such
                        as&nbsp;<a
                        href="https://www.aboutcookies.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >www.aboutcookies.org</a
                    >. For help with how to manage and delete cookies, visit&nbsp;<a
                        href="http://www.aboutcookies.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >www.aboutcookies.org</a
                    >. Further information about advertising cookies, and how to manage
                        them, can be found at&nbsp;<a
                        href="http://www.youronlinechoices.eu/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >youronlinechoices.eu</a
                    >&nbsp;(EU based), or&nbsp;<a
                        href="http://www.aboutads.info/choices/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >aboutads.info</a
                    >&nbsp;(US based).
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We are not responsible for the content or cookies from external
                        websites. If you follow a link from our site to a third-party site, they
                        will have their own privacy and cookie notices.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Please bear in mind that if you restrict or disable cookies it can limit
                        functionality and prevent sites from working properly at all.
                    </p>
                    <h6
                        className="mx-0 mt-0 mb-10 text-lg leading-6 text-red-900 md:text-2xl md:leading-7 md:mx-0 md:mt-0 md:mb-8"
                    >
                        Keep in mind
                    </h6>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Multiple users </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        If more than one person uses your device, the choices set up by other
                        people will apply to you as well unless you change them. For example, if
                        you share a computer with your family, you may see ads based on sites
                        they have visited as well as sites you have visited. <br/>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Cookies that are already on your device </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Turning off one or more types of cookies will not delete any that have
                        been downloaded in the past.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong>Contact us </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Our address is Costa Limited, Costa House, 6 Porz Avenue, Houghton Hall
                        Business Park, Houghton Regis, Dunstable, Beds, LU5 5YG. You can also
                        contact us by email at
                        <a
                            href="mailto:costafeedback@costacoffee.com"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >costafeedback@costacoffee.com</a
                        >. If you have any queries in relation to data privacy, please email us
                        at
                        <a
                            href="mailto:costadpo@costacoffee.com"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >costadpo@costacoffee.com</a
                        >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Cookies and similar technology used by us are listed below.
                    </p>
                </div>
            </article>
            <div className="text-gray-800 bg-gray-100 p-6">
                <table
                    aria-label="Cookie Policy Table"
                    className="block overflow-hidden relative py-10 text-sm bg-gray-200 rounded-sm xl:my-0 xl:mx-auto xl:px-8 xl:py-10 md:border-collapse md:text-base md:leading-normal md:py-10"
                >
                    <thead className="block text-base leading-6 border-collapse">
                        <tr className="block absolute md:bg-white md:flex md:static">
                            <th
                                aria-label="Cookie"
                                className="block relative py-5 px-6 font-sans text-left break-words border-none md:text-lg w-1/3"
                            >
                                Cookie
                            </th>
                            <th
                                aria-label="Purpose"
                                className="block relative py-5 px-6 font-sans text-left break-words border-none w-2/3 md:text-lg"
                            >
                                Purpose
                            </th>
                        </tr>
                    </thead>
                    <tbody className="block text-base leading-6 border-collapse">
                        <tr className="block mb-8 md:bg-gray-100 md:flex md:mb-0">
                            <td
                                className="block relative py-5 px-6 text-left break-words border-none md:bg-transparent  w-1/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    costa_challenge_session
                                </p>
                            </td>
                            <td
                                className="block relative py-5 px-6 text-left break-words bg-gray-100 border-none md:bg-transparent w-2/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Type</strong>: Strictly necessary - First party cookie.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Expires</strong>: After 1 Day.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Purpose</strong>: Used for authenticating user sessions.
                                </p>
                            </td>
                        </tr>
                        <tr className="block mb-8 md:bg-white md:flex md:mb-0">
                            <td
                                className="block relative py-5 px-6 text-left break-words border-none md:bg-transparent w-1/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7 p-2">
                                    XSRF-TOKEN
                                </p>
                            </td>
                            <td
                                className="block relative py-5 px-6 text-left break-words bg-gray-100 border-none md:bg-transparent w-2/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Type:</strong> Necessary – First party cookie.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Expires</strong>: After 1 Day.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Purpose</strong>: The XSRF token is used to enhance security by preventing
                                    cross-site request forgery.
                                </p>
                            </td>
                        </tr>
                        <tr className="block mb-8 md:bg-white md:flex md:mb-0">
                            <td
                                className="block relative py-5 px-6 text-left break-words border-none md:bg-transparent w-1/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7 p-2">
                                    _ga
                                </p>
                            </td>
                            <td
                                className="block relative py-5 px-6 text-left break-words bg-gray-100 border-none md:bg-transparent w-2/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Type:</strong> Analytics – Third party cookie.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Expires</strong>: After 2 years.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Purpose</strong>: Used to distinguish users.
                                </p>
                            </td>
                        </tr>
                        <tr className="block mb-8 md:bg-white md:flex md:mb-0">
                            <td
                                className="block relative py-5 px-6 text-left break-words border-none md:bg-transparent w-1/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7 p-2">
                                    _gid
                                </p>
                            </td>
                            <td
                                className="block relative py-5 px-6 text-left break-words bg-gray-100 border-none md:bg-transparent w-2/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Type:</strong> Analytics – Third party cookie.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Expires</strong>: After 24 hours.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Purpose</strong>: Used to distinguish users.
                                </p>
                            </td>
                        </tr>
                        <tr className="block mb-8 md:bg-white md:flex md:mb-0">
                            <td
                                className="block relative py-5 px-6 text-left break-words border-none md:bg-transparent w-1/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7 p-2">
                                    _gat
                                </p>
                            </td>
                            <td
                                className="block relative py-5 px-6 text-left break-words bg-gray-100 border-none md:bg-transparent w-2/3"
                            >
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Type:</strong> Analytics – Third party cookie.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Expires</strong>: After 1 minute.
                                </p>
                                <p className="mt-0 font-sans md:text-lg md:leading-7">
                                    <strong>Purpose</strong>: Used to throttle request rate.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-8">
                <Link to="/" className="bg-white rounded-md p-3 text-app-plum">
                    Go Back
                </Link>
            </div>
        </main>
    );
}

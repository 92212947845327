import axios from 'axios';

const publicPath = window.asset_url + '/' ?? '/';

/**
 * @method asset
 * @param {string} path
 * @return {string}
 */
const asset = (path) => {
    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    return publicPath + path;
}


/**
 * @method isAdminPage
 * @return {boolean}
 */
const isAdminPage = () => {
    return location.pathname.startsWith('/admin');
}

/**
 * @method mergeObjectsIntoPaginationData
 * @param {object} data
 * @param {array} objects
 * @param {boolean} reverse
 * @param {object} sameObjectCheck
 * @return {object}
 */
const mergeObjectsIntoPaginationData = (data, objects, reverse = false, sameObjectCheck = null) => {
    // Fallback to just checking on id.
    if (!sameObjectCheck) {
        sameObjectCheck = (o, object) => { return o.id === object.id };
    }

    if (!objects) {
        return data;
    }

    if (!data) {
        return {
            data: objects
        }
    }

    if (reverse) {
        objects.reverse().forEach(function(object) {
            data = mergeObjectIntoPaginationData(data, object, reverse, sameObjectCheck);
        })
    } else {
        objects.forEach(function(object) {
            data = mergeObjectIntoPaginationData(data, object, reverse, sameObjectCheck);
        })
    }

    return data;
}

/**
 * @method mergeObjectIntoPaginationData
 * @param {object} data
 * @param {object} object
 * @param {boolean} reverse
 * @param {object} sameObjectCheck
 * @return {object}
 */
const mergeObjectIntoPaginationData = (data, object, reverse, sameObjectCheck) => {
    if (data.data.filter(o => sameObjectCheck(o, object)).length === 0) {
        data = {
            data: reverse ? [
                object,
                ...data.data
            ] : [
                ...data.data,
                object
            ],
            meta: data.meta
        };
    }

    return data;
}

/**
 * @method capitalizeFirstLetter
 * @param {string} string
 * @return {string}
 */
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @method downloadFileFromApi
 * @param {string} url
 * @param {string} filename
 */
const downloadFileFromApi = (url, filename) => {
    axios({
        url,
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
    });
}

export {
    asset,
    isAdminPage,
    mergeObjectsIntoPaginationData,
    capitalizeFirstLetter,
    downloadFileFromApi
}

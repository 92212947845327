import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Label from './Label';

const Input = React.forwardRef(
    (
        {
            containerClassName, type, label, placeholder, id, onChange, value, readOnly, instructions, required = true,
            labelTextColor
        }, ref,
    ) => {
        let classes = 'rounded-md border-white border border-opacity-60 text-left text-app-plum placeholder-app-plum uppercase' +
            'placeholder-opacity-60 bg-white w-full focus:border-app-brown focus:ring-app-brown';

        if (!isAdminPage()) {
            // overwrite classes as required for non-admin pages.
        }

        return (
            <div className={containerClassName}>
                {label && (<Label textColor={labelTextColor} label={label} htmlFor={id} instructions={instructions} />)}

                <input
                    ref={ref}
                    readOnly={readOnly}
                    type={type}
                    id={id}
                    value={value ?? ''}
                    onChange={(e) => onChange(type === 'file' ? e.target.files : e.target.value)}
                    placeholder={placeholder}
                    className={classes}
                    required={required}
                />
            </div>
        );
    });

export default Input;

import React from 'react';

import AdminLayout from '../AdminLayout';

import StatsApi from 'Services/Api/Admin/Stats';
import {Loading} from 'Components/Partials';

import {Toast} from 'Services';

class StatsIndex extends React.Component {
    /**
     * @var state
     * @type {{stats: null, working: boolean}}
     */
    state = {
        working: false,
        stats: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchStats();
    };

    /**
     * @method fetchStats
     * @return {Promise<void>}
     */
    fetchStats = async () => {
        this.setState({working: true});

        const request = await StatsApi.get(null);

        if (request.success) {
            return this.setState({
                stats: request.data.data,
                working: false
            });
        }

        Toast.error();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, stats} = this.state;

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6 flex justify-between items-center">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Stats
                        </h2>

                        <button onClick={this.fetchStats} className="text-teal-500 hover:underline">
                            Refresh
                        </button>
                    </div>
                </div>

                <div className="p-6">
                    {working && (
                        <Loading />
                    )}

                    {!working && stats &&
                        <p className="mb-2 font-bold text-center">
                            Please note that actions by admin users are ignored when calculating these statistics.
                        </p>
                    }

                    {!working && stats && Object.entries(stats).map((stats2, i) => {
                        return (
                            <div className="mb-8" key={i}>
                                <p className="text-xl font-bold mb-2">
                                    {stats2[0]}
                                </p>

                                <div className="grid grid-cols-4 gap-4">
                                    {stats2[1].map(stat => (
                                        <div className="bg-gray-200 flex flex-col justify-between items-center text-center px-4 py-8">
                                            <span className="mb-4 text-xl">{stat.key}</span><br />
                                            <span className="text-3xl text-light">{stat.value}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </AdminLayout>
        );
    }
}

export default StatsIndex;

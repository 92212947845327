import {
    faUsersCog, 
    faUsers, 
    faLayerGroup,
    faGlobeEurope, 
    faChartLine, 
    faFileExcel, 
    faEnvelope} from '@fortawesome/free-solid-svg-icons';

export default [
    {
        to: "/admin",
        icon: faUsersCog,
        title: "Admin",
    },
    {
        icon: faUsers,
        title: "Users",
        sub: [
            {
                to: "/admin/users",
                icon: faUsers,
                title: "Users",
            },
            {
                feature: "groups",
                to: "/admin/groups",
                icon: faLayerGroup,
                title: "Groups",
            },
        ]
    },
    {
        to: "/admin/translations",
        icon: faGlobeEurope,
        title: "Translations",
    },
    {
        to: "/admin/stats",
        icon: faChartLine,
        title: "Stats",
    },
    {
        to: "/admin/reports",
        icon: faFileExcel,
        title: "Reports",
    },
    {
        to: "/admin/imports",
        icon: faFileExcel,
        title: "Imports",
    },   
    {
        to: "/admin/push-notifications",
        icon: faEnvelope,
        title: "Push Notifications",
    }
];
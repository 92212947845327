import React from 'react';

import AdminLayout from '../AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, Select} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminGroupsApi from 'Services/Api/Admin/Groups';

class GroupsStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Group ' + (this.props.match?.params?.group ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{group_id: string}}
     */
    state = {
        group_id: this.props.match?.params?.group ?? '',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.group_id) {
            this.loadGroup();
        }
    }

    /**
     * @method loadGroup
     */
    loadGroup = async () => {
        const {group_id} = this.state;

        const response = await AdminGroupsApi.get(group_id);

        let group = response.data.data;

        this.props.setForm({
            name: group.name,
            scopes: group.scopes,
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {group_id} = this.state;

        if (group_id) {
            return AdminGroupsApi.patch(group_id, form);
        } else {
            return AdminGroupsApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {group_id} = this.state;
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        let scopes = window.base.scopes.map((scope, key) => {
            return {
                label: scope,
                value: scope
            };
        });

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Groups - {group_id ? 'Update' : 'Create'} Group
                        </h2>
                    </div>
                </div>

                {!window.base.features.groups &&
                    <p className="m-6 font-bold">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.groups &&
                    <div className="m-4">
                        <form
                            autoComplete="off"
                            className="divide-y divide-gray-200 lg:col-span-9"
                            onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (group_id ? false : true))}
                        >
                            {alert !== null && (<Alert {...alert} />)}

                            <div className="grid grid-cols-2 gap-4 border-0">
                                <div>
                                    <Input
                                        label="Name"
                                        value={form.name}
                                        id="name"
                                        onChange={(v) => handleInput('name', v)}
                                    />
                                </div>

                                <div>
                                    <Select
                                        label="Scopes"
                                        value={form.scopes}
                                        onChange={(v) => handleInput('scopes', v)}
                                        options={scopes}
                                        isMulti
                                    />
                                </div>
                            </div>

                            <div className="p-6 flex justify-end">
                                <PrimaryButton
                                    text="Save"
                                    working={working}
                                />
                            </div>
                        </form>
                    </div>
                }
            </AdminLayout>
        )
    }
}

export default FormHandler(GroupsStore);

import React from 'react';

import AdminLayout from '../AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, Checkbox, Toggle, Select} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminUsersApi from 'Services/Api/Admin/Users';
import AdminGroupsApi from 'Services/Api/Admin/Groups';

class UsersStore extends React.Component {

    /**
     * @var success
     * @type {string}
     */
    success = 'User ' + (this.props.match?.params?.user ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{user_id: string}}
     */
    state = {
        user_id: this.props.match?.params?.user ?? '',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.user_id) {
            this.loadUser();
        } else {
            this.props.setInitialValues({
                full_name: '',
                email: '',
                password: '',
                password_confirmation: '',
                is_admin: false,
                enabled: true,
                automatically_verify_email: false,
                group_id: null,
                send_user_invite: false,
                send_password_reset_link: false,
            });
        }
    }

    /**
     * @method loadUser
     * @param {int} page
     */
    loadUser = async (page = 1) => {
        const {user_id} = this.state;

        const response = await AdminUsersApi.getUser(user_id);

        this.props.setForm(response.data.data);
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {user_id} = this.state;

        if (user_id) {
            return AdminUsersApi.updateUser(user_id, form);
        } else {
            return AdminUsersApi.storeUser(form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {user_id} = this.state;
        const {form, working, alert, setForm, handleInput, handleSubmit} = this.props;

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Users - {user_id ? 'Update' : 'Create'} User
                        </h2>
                    </div>
                </div>

                <div className="m-4">
                    <form
                        autoComplete="off"
                        className="divide-y divide-gray-200 lg:col-span-9"
                        onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (user_id ? false : true))}
                    >
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid grid-cols-2 gap-4 mb-4 border-0">
                            <div>
                                <Input
                                    label="Full Name"
                                    value={form.full_name}
                                    id="full_name"
                                    onChange={(v) => handleInput('full_name', v)}
                                />
                            </div>

                            { !user_id &&
                                <>
                                    <div>
                                        <Input
                                            label="Email"
                                            value={form.email}
                                            id="email"
                                            onChange={(v) => handleInput('email', v)}
                                        />
                                    </div>

                                    <div>
                                        <Toggle
                                            label="Send User Invite Email"
                                            labelPlacement="top"
                                            value={form.send_user_invite}
                                            id="send_user_invite"
                                            value={form.send_user_invite}
                                            onChange={(v) => {
                                                if (v === false) {
                                                    setForm({
                                                        ...form,
                                                        send_user_invite: false,
                                                        send_password_reset_link: false
                                                    });
                                                } else {
                                                    handleInput('send_user_invite', v);
                                                }
                                            }}
                                        />
                                    </div>

                                    {(form.is_admin || !window.base.using_hard_password) &&
                                        <>
                                            {form.send_user_invite &&
                                                <div>
                                                    <Toggle
                                                        label="Send User Invite Email With Secure Link To Set Password"
                                                        labelPlacement="top"
                                                        value={form.send_password_reset_link}
                                                        id="send_password_reset_link"
                                                        value={form.send_password_reset_link}
                                                        onChange={(v) => handleInput('send_password_reset_link', v)}
                                                    />
                                                </div>
                                            }

                                            {!form.send_password_reset_link &&
                                                <>
                                                    <div>
                                                        <Input
                                                            label="Password"
                                                            type="password"
                                                            value={form.password}
                                                            id="password"
                                                            onChange={v => handleInput('password', v)}
                                                        />
                                                    </div>

                                                    <div>
                                                        <Input
                                                            label="Password Confirmation"
                                                            type="password"
                                                            value={form.password_confirmation}
                                                            id="password_confirmation"
                                                            onChange={v => handleInput('password_confirmation', v)}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                    {window.base.features.verify_registrations &&
                                        <div className="my-auto">
                                            <Checkbox
                                                label="Automatically Verify Email"
                                                value={form.automatically_verify_email}
                                                id="automatically_verify_email"
                                                onChange={(v) => handleInput('automatically_verify_email', v.target.checked)}
                                            />
                                        </div>
                                    }
                                </>
                            }

                            <div>
                                <Toggle
                                    label="Admin"
                                    labelPlacement="top"
                                    value={form.is_admin}
                                    id="is_admin"
                                    value={form.is_admin}
                                    onChange={(v) => handleInput('is_admin', v)}
                                />
                            </div>

                            {!form.is_admin && window.base.features.groups &&
                                <div>
                                    <Select
                                        label="Group"
                                        id="group"
                                        value={form.group_id}
                                        onChange={(v) => handleInput('group_id', v)}
                                        options={form.group ? [{
                                                label: form.group.name,
                                                value: form.group.id
                                            }] : null}
                                        isAsync
                                        searchCallback={(page, search) => AdminGroupsApi.get(null, {page, search})}
                                        searchLabelKey="name"
                                        allowNull={true}
                                    />
                                </div>
                            }

                            <div>
                                <Toggle
                                    label="Enabled"
                                    labelPlacement="top"
                                    value={form.enabled}
                                    id="enabled"
                                    value={form.enabled}
                                    onChange={(v) => handleInput('enabled', v)}
                                />
                            </div>
                        </div>

                        <div className="p-6 flex justify-end">
                            <PrimaryButton
                                text="Save"
                                working={working}
                            />
                        </div>
                    </form>
                </div>
            </AdminLayout>
        )
    }
}

export default FormHandler(UsersStore);

import {DateTime} from 'luxon';
import Closed from 'Pages/Closed';
import React from 'react';
import CountdownElement from 'Pages/Countdown';
import {withRouter} from 'react-router-dom';
import User from 'Services/User';

/**
 * @function Guest
 * @param {*|JSX.Element} component
 * @param {string} pathname
 * @return {*|JSX.Element}
 * @constructor
 */
const Countdown = ({component, location: {pathname}}) => {
    const timeUntil = DateTime.fromISO(process.env.MIX_RELEASE).diffNow().as('milliseconds');
    const endAt = DateTime.fromISO(process.env.MIX_CLOSE_AT).diffNow().as('milliseconds');

    if (endAt > 0) {
        setInterval(() => {
            const endAt = DateTime.fromISO(process.env.MIX_CLOSE_AT).diffNow().as('milliseconds');

            if (endAt < 0) {
                location.reload();
            }
        }, 1000);
    }

    const storage = localStorage.getItem('is_admin') ?? false;
    const isAdmin = (User.loggedIn && User.data.is_admin) || (storage && pathname === '/login')

    return timeUntil < 0 ? (!isAdmin && endAt < 0) ? (<Closed />) : (component) : <CountdownElement />;
};

export default withRouter(Countdown);

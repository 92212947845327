import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeMute, faVolumeUp} from '@fortawesome/free-solid-svg-icons';

export default class Game extends React.Component {
    /**
     * @var state
     * @type {{screen: string, soundMuted: boolean}}
     */
    state = {
        screen: 'game',
        soundMuted: false,
        currentTime: 0,
    };

    /**
     * @var soundTracks
     * @type {{}}
     */
    soundTracks = {}

    /**
     * @var soundPlaying
     * @type {{}}
     */
    soundPlaying = {};

    /**
     * @var mode
     * @type {string}
     */
    mode = 'landscape';

    /**
     * @var timeLimit
     * @type {number}
     */
    timeLimit = 30;

    /**
     * @var currentTime
     * @type {number}
     */
    currentTime = 0;

    /**
     * @var timerInterval
     * @type {null}
     */
    timerInterval = null;

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        window.addEventListener('message', this.handleMessage);

        this.startTimeLimit();
    };

    /**
     * @method handleCancelTimer
     */
    handleCancelTimer = () => {
        if (this.timerInterval !== null) {
            window.clearInterval(this.timerInterval);
        }
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        window.removeEventListener('message', this.handleMessage);

        if (this.tracks) {
            this.tracks.forEach((track) => {
                this.handleStopSound(track.name);
            });
        }

        if (this.timerInterval !== null) {
            window.clearInterval(this.timerInterval);
        }
    };

    /**
     * @method startTimeLimit
     */
    startTimeLimit = () => {
        if (!this.timeLimit) {
            return;
        }

        this.timerInterval = setInterval(() => {
            this.currentTime++;

            if (this.currentTime > this.timeLimit) {
                this.props.handleTimesUp();
            }

            this.setState({
                currentTime: this.currentTime,
            })
        }, 1000);
    };

    /**
     * @method handleMessage
     * @param e
     */
    handleMessage = (e) => {
        if (e.data === `${this.gameName}-game-over`) {
            this.handleGoToScreen('game-over');
        }
    };

    /**
     * @method handleGoToScreen
     * @param {string} screen
     */
    handleGoToScreen = (screen) => {
        this.setState({
            screen
        }, () => {
            if (screen === 'end') {
                this.props.onComplete();
            }
        });
    };

    /**
     * @method handlePlaySound
     * @param {string} track
     * @param {boolean} loop
     */
    handlePlaySound = (track, loop = false) => {
        this.soundPlaying[track] = this.getTrack(track);

        if (this.soundPlaying[track]) {
            this.soundPlaying[track].loop = loop;
            this.soundPlaying[track].muted = this.state.soundMuted;
            this.soundPlaying[track].play();
        }
    };

    /**
     * @method handleToggleMute
     */
    handleToggleMute = () => {
        const soundMuted = !this.state.soundMuted;

        this.setState({
            soundMuted
        });

        for (const track in this.soundPlaying) {
            this.soundPlaying[track].muted = soundMuted;
        }
    };

    /**
     * @method handleStopSound
     * @param track
     */
    handleStopSound = (track) => {
        this.soundPlaying[track] = this.getTrack(track);

        if (this.soundPlaying[track]) {
            this.soundPlaying[track].pause();
            this.soundPlaying[track].currentTime = 0;
        }
    }

    /**
     * @method getTrack
     * @return {*}
     */
    getTrack = (track) => {
        if (this.soundTracks[track] === undefined) {
            console.error(`track not found ${track}`);
            return;
        }

        return this.soundTracks[track];
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {screens} = this;
        const {size} = this.props;

        return (
            <React.Fragment>
                <screens.game
                    size={size}
                    setPoints={this.props.setPoints}
                    setAdditional={this.props.setAdditional}
                    handleTimesUp={this.props.handleTimesUp}
                    handleGoToScreen={this.handleGoToScreen}
                    handlePlaySound={this.handlePlaySound}
                    handleStopSound={this.handleStopSound}
                    handleCancelTimer={this.handleCancelTimer}
                    timeLimit={this.timeLimit}
                    currentTime={this.state.currentTime}
                />
            </React.Fragment>
        );
    }
}

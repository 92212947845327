import { Link } from 'react-router-dom';

export default function Privacy() {
    return (
        <main
            className="block my-6 mx-auto leading-7 text-gray-800 md:my-12 max-w-4xl"
        >
            <article className="my-0 text-gray-800 lg:my-0 md:my-0">
                <div
                    className="overflow-hidden my-12 text-base leading-normal bg-gray-200 p-6 md:text-lg"
                >
                    <h2
                        className="mx-0 mt-0 mb-10 text-3xl leading-9 text-red-900 md:text-6xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        Privacy policy
                    </h2>
                    <h6
                        className="mx-0 mt-0 mb-10 text-lg leading-6 text-red-900 md:text-2xl md:leading-7 md:mx-0 md:mt-0 md:mb-8"
                    >
                        May 2021
                    </h6>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Costa Limited ("Costa", "We", "Us") is a controller of your personal
                        data. We respect your data and your privacy is important to us.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        This Privacy Notice explains what personal data we collect and how it is
                        used. This notice also explains what rights you have over your personal
                        data and how you can use those rights.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You have the right to object to some of the processing which Costa
                        carries out. More information about your rights and how to exercise
                        these is set out in the “<a
                        href="#your-rights"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Your rights</a
                    >” section of this notice.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        An overview of how we use your data is {" "}
                        <a
                            href="https://www.costa.co.uk/docs/your_costa_data.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >here</a
                        >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Costa Limited’s registered office is Costa House, 6 Porz Avenue,
                        Houghton Hall Business Park, Houghton Regis, Dunstable, Beds, LU5 5YG.
                    </p>
                    <ol className="pl-10">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#summary-of-how-we-use-your-data-and-your-rights"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className=""
                                >Summary of how we use your data and your rights</strong
                                ></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#information-we-collect-from-you"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">Information we collect from you</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#information-we-receive-from-third-parties"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className=""
                                >Information we receive from third parties</strong
                                ></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#how-we-use-information-and-the-legal-basis"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className=""
                                >How we use information and the legal basis</strong
                                ></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#data-sharing"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">Data sharing</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#international-transfers"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">International transfers</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#cookies-and-similar-technologies"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">Cookies and similar technologies</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#data-retention"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">Data retention</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#your-rights"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">Your rights</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#contact-details"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className="">Contact details</strong></a
                                >
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                <a
                                    href="#which-costa-entity-is-the-controller"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                ><strong className=""
                                >Which Costa entity is the controller?</strong
                                ></a
                                >
                            </p>
                        </li>
                    </ol>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        1.&nbsp;Summary of how we use your data and your rights
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We use your data to provide and improve our products and services,
                        including for marketing, research, feedback and enquiries, and for
                        safety and security purposes. We also use your data when you enter
                        competitions or awards that we organise.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We will use your data to comply with laws and regulations. We use your
                        data to prevent and detect crime, such as fraud.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You have the right to object to some of the processing Costa carries
                        out.&nbsp;More information about your rights and how to exercise
                        these&nbsp;is&nbsp;set out in the “<a
                        href="#your-rights"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Your rights</a
                    >” section of this notice.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        When you give consent, you are able to withdraw that consent at any
                        time, for instance by emailing&nbsp;<a
                        href="mailto:mycostadata@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >mycostadata@costacoffee.com</a
                    >. You can also email&nbsp;<a
                        href="mailto:mycostadata@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >mycostadata@costacoffee.com</a
                    >&nbsp;to exercise any other data rights, such as obtaining a copy of
                        your data, correcting, deleting or restricting how we use your data.
                        Please see&nbsp;“<a
                        href="#your-rights"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Your rights</a
                    >” for more information.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You can unsubscribe from marketing communications at any time. To opt
                        out of direct marketing, including profiling for direct marketing
                        purposes, you can either adjust the preference settings in your Costa
                        Club account, or select “unsubscribe” in emails, or email&nbsp;<a
                        href="mailto:costacoffeeclub@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >costacoffeeclub@costacoffee.com</a
                    >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Our websites and app use cookies and similar technologies to improve
                        functionality, recognise you and to customise your experience. You can
                        reject and block cookies in your browser settings. Please see
                        our&nbsp;<a
                        href="https://www.costa.co.uk/cookie-policy"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Cookie Notice</a
                    >&nbsp;for more information.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        If you enable location services on the app, or you access the location
                        finder on our sites and your browser settings allow this, your device
                        will identify and alert you to the nearest Costa Store and Costa Express
                        to your location.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Costa is part of the Coca Cola group of companies, for details of how
                        personal data is shared with the Coca Cola group, please see the “<a
                        href="#data-sharing"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Data Sharing</a
                    >” section below.
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        2.&nbsp;Information we collect from you
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We collect information when you purchase something or use our services
                        or enter our competitions or awards. This includes store visits, using
                        our websites or app, joining our Costa Coffee Club, or corresponding
                        with us.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        In particular:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We keep information you give us directly such as contact details
                                (including name, email, address and telephone number), comments,
                                date of birth, gender, region, frequency of visits, feedback,
                                marketing opinions and competition entries.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We record and analyse store, web and app visits, details of your
                                purchases, including when you purchase or use e-Gifts, and where you
                                take advantage of our promotions.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We record your car registration for our Click &amp; Serve service to
                                bring you your order.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                When you sign up to in-store WiFi the provider will use your MAC
                                code and device i.d. for access and security purposes.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                If there is an incident, we log information about it.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                If you engage with us online via our websites or app, our cookies
                                and similar technologies will capture your IP address, your
                                location, and record how you use the site or app to help improve it
                                and improve your user experience, where your browser settings or
                                permission allows for this.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                If you post information online about us or provide feedback, we keep
                                a record.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                If you contact us directly and complain or give feedback, receive
                                compensation, or enter a competition, we will record details and all
                                related information (including that you provide to us) such as
                                emails, letters, phone calls, date of birth to our product customer
                                information helplines including those operated by third parties as
                                detailed in Section 5 below.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We use CCTV in our premises for the prevention and detection of
                                crime and for safety and security reasons.
                            </p>
                        </li>
                    </ul>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        3.&nbsp;Information we receive from third parties
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We receive your information from other people in certain circumstances.
                        This can happen when:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Someone buys you a Gift card or eGift. They give your name and email
                                address, so we can send you the Gift card or eGift. Occasionally
                                they may give us your birthday information (day and month) as well,
                                so that we send the Gift card or eGift to you on your birthday.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                You participate in market research, such as focus groups or surveys.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We receive your Costa Coffee Club number from O2 to validate your
                                Costa Coffee Club membership for unlimited in-store WiFi access.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Members of the Coca-Cola group may help us operate some of our
                                customer information centre services for Costa branded products and
                                provide us with information that you supply to them – see section 5
                                for more details.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We may also indirectly collect from our social media partners the
                                fact that you are using their social networks and your associated
                                advertising identifiers.
                            </p>
                        </li>
                    </ul>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        4. How we use information and the legal basis
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We are allowed to use your data only if we have a proper reason to do so
                        such as:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To fulfil a contract we have with you;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                When it is in our legitimate interest;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                When you consent to it; or
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To comply with the law.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        A legitimate interest is when we have a business or commercial reason to
                        use your data. This involves us making an assessment of when we can rely
                        on our legitimate interests. For more information on this assessment
                        please contact &nbsp;<a
                        href="mailto:costadpo@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >costadpo@costacoffee.com</a
                    >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We have set out below how and why we use your personal information and
                        the legal basis we rely on. This is also where we tell you what our
                        legitimate interests are.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        ><strong className="italic"
                        >When you buy something from us, join our Costa Coffee Club, or
                            enter a competition we run, we use your information to fulfil our
                            contract with you.
                        </strong></em
                        >
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We take information to communicate with you, check your identity, take
                        payment, and provide products and services, including awarding loyalty
                        points if you are a Coffee Club member.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <em className="italic"
                        ><strong className="italic"
                        >To run our business and pursue our legitimate interests, we use
                            your information.
                        </strong></em
                        >
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Our legitimate interests include keeping our records up to date,
                        fulfilling our legal, compliance and contractual duties, working out
                        which of our products and services may interest you, improving our site
                        and apps, and services, developing new products and services, and
                        telling you about them and conducting market research.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong className="">Further details of our legitimate interests: </strong>
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        To run and promote our business, we use your information:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To provide and improve our products and services, including in-store
                                Wifi, Costa Coffee Club and Costa eGift, and to respond to you if
                                you contact us.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To record call centre communications, including incoming and
                                outgoing calls and emails, for staff training, quality improvement
                                purposes and establishing facts and to deal with concerns or
                                complaints that you may raise.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                When we monitor Costa websites, social media platforms such as
                                Facebook and Twitter and online services including our mobile app
                                and responses to email marketing. If you post comments online or in
                                other media, we capture this information, use it to contact you, and
                                use it to improve our products and services.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Show targeted advertisements within your social networks and to
                                other people like you.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To run competitions and promotions and track which offers seem of
                                interest to you.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To understand you better as a customer by analysing your
                                transactions and other information you provide to us or which we
                                learn through your interactions with us.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To send you emails including offers tailored to your perceived
                                preferences where you are a Costa Coffee Club member and your
                                preference settings permit this. We record which emails seem to be
                                of interest to you. Based on your purchase history and membership
                                card usage, some Costa Coffee Club members may be offered additional
                                loyalty points.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To contact you where you provide us with market research feedback or
                                pass this data to a third-party business partner of ours for panel
                                market research analysis.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                To administer and run our organised awards, including the Costa Book
                                Awards, and to promote the awards through the publishing of winners'
                                details.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We also process the insight data for our legitimate interests to
                        understand how you interact with our Facebook page and its contents and
                        to help us improve our services and contents.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        To prevent, investigate and/or report fraud, terrorism,
                        misrepresentation, security incidents or crime, including where we are
                        required to do so by law, we:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Monitor Costa Coffee Club accounts and Gift card and eGift usage and
                                review CCTV, record call centre communications and emails.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Use other organisations to check the validity of the credit or debit
                                card details you use to pay (for further details see “Data sharing”
                                below).
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        To comply with law, assess and uphold legal or contractual rights and
                        claims, and for monitoring, auditing and training on compliance matters:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We monitor, and record call centre communications, including
                                incoming and outgoing calls and emails.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We verify your identity in certain circumstances.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We keep records to comply with health and safety legislation,
                                including accounting for the number of individuals on our premises
                                and logging accidents.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong className=""
                        ><em className="italic">If you give us consent, we: </em></strong
                        >
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Send you electronic marketing, including promotions and offers, in
                                relation to our products and services if you are a member of our
                                Costa Coffee Club and inform you of other outlets that award Costa
                                Coffee Club points.
                                <em className="italic"
                                >Costa Coffee Club members can subscribe or unsubscribe from our
                                    marketing communications at any time. For instance, preferences
                                    can be adjusted in account settings online.
                                </em>
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Use cookies or similar technologies on the website, app and in
                                marketing emails, including analytic cookies. For more details on
                                our use of such technologies,
                                <a
                                    href="https://www.costa.co.uk/cookie-policy/"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                                >click here</a
                                >
                                to see our Cookie Notice.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Show you targeted advertisements within your social media networks
                                if your consent is required by law (e.g. in Germany).
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Through the settings on your device, send you push notifications
                                through the app.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                If you use the store locator in the app or site and enable location
                                services, it will notify you of the nearest Costa or Costa Express.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Use data for other purposes where we explain that purpose when we
                                ask for your consent.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        When you give consent, you are able to withdraw that consent at any time
                        by contacting us, for instance by emailing
                        <a
                            href="mailto:mycostadata@costacoffee.com"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >mycostadata@costacoffee.com</a
                        >. If you do so we can only continue to use your data if another legal
                        basis applies, such as when we’re required to do something by law.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Nevertheless, you have an absolute right to opt-out of direct marketing,
                        including profiling for direct marketing purposes, at any time. You can
                        opt out of marketing by selecting “unsubscribe” in emails or by
                        adjusting the preference settings on your Costa Coffee Club account or
                        by emailing
                        <a
                            href="mailto:costacoffeeclub@costacoffee.com"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >costacoffeeclub@costacoffee.com</a
                        >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        <strong className=""
                        >When the law requires us to process your data we will do so. This can
                            include:
                        </strong>
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Legal, compliance, regulatory and investigative purposes, including
                                for government agencies and law enforcement.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                When you exercise your rights under data protection legislation,
                                including when you ask to unsubscribe from our marketing
                                communications.
                            </p>
                        </li>
                    </ul>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        5.&nbsp;&nbsp;Data Sharing
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Costa is part of the Coca-Cola group of companies. Coca-Cola group
                        companies may process your personal data in the course of assisting us
                        with customer information services, for instance SA Coca-Cola Services
                        NV operates the Costa 'Ready To Drink' product customer care line and
                        will handle enquiries and concerns regarding that product as its
                        manufacturer. Details of such enquiries and concerns will be shared with
                        Costa if there is a specific complaint relating to Costa that Coca Cola
                        is unable to handle.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        For some activities Costa uses third party service providers, for
                        instance O2 provide WiFi in our stores. When these service providers
                        need customer data from you, we share information with them, such as
                        whether a correct Costa Coffee Club member number has been entered to
                        access WiFi.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        In addition to using the companies as described above, we use third
                        party providers for the following services:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                WiFi (O2)
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Sending promotional offers
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Customer feedback surveys
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Customer service queries and complaints
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Gift card and eGift services
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Data analysis to enable us to optimise our services (including
                                locations and products) Gift cards (including eGifts)
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Loyalty scheme platform
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Insurance
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                IT development, support, maintenance and hosting, including the
                                provision of applications and website hosting
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Payments’ processing to enable you to pay by credit or debit card
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                CCTV system provision and maintenance
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Administration of our competitions and awards, for example our work
                                with the Booksellers Association and panel judges on the Costa Book
                                Awards.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We may also share data with Social Networks (e.g. Facebook).
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        If our business is to be integrated with another business or sold, your
                        details would be shared with our advisers and any prospective
                        purchaser’s advisers. Your information could be passed to the new
                        owners. (You will be notified if this happens).
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Personal data may be shared with government authorities and/or law
                        enforcement officials for the prevention or detection of crime, if
                        required by law or if required for a legal or contractual claim.
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        6.&nbsp;International transfers
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Sometimes we send or store your data outside of the European Economic
                        Area (the EU plus Iceland, Lichtenstein and Norway) (‘EEA’). For
                        example, to follow your instructions, comply with a legal duty or to
                        work with or receive services from our service providers who we use to
                        help run your accounts and our services.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        If we do transfer information outside of the EEA, we will make sure that
                        it is protected by using one of these safeguards:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Transfer it to a non-EEA country with privacy laws that give the
                                same protection as the EEA. Some countries have been deemed adequate
                                by the EU.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Put in place a contract with the recipient that means they must
                                protect it to the same standards as the EEA or use other mechanisms
                                and measures to achieve adequate protection. We also may use the
                                Standard Contractual Clauses published by the EU.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Binding corporate rules. These are internal rules adopted by group
                                companies to allow international transfers of personal data to
                                entities within the same corporate group located in countries which
                                do not provide an adequate level of protection.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        For our service provider in the US, who helps us with our customer
                        feedback surveys, we rely on contractual measures. For our service
                        provider in India, who has restricted access to some data to provide us
                        with IT support and maintenance services, we rely on contractual
                        measures. For further details on the mechanisms used please contact{" "}
                        <a
                        href="mailto:costadataprotectionofficer@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >costadpo@costacoffee.com</a
                    >.
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        7. Cookies and similar technologies
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Our website, apps and marketing emails use cookies and similar
                        technology. Full information is in our {" "}
                        <a
                            href="https://www.costa.co.uk/cookie-policy/"
                            target="_self"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >Cookie Notice</a
                        >. This includes information on how to adjust your browser settings to
                        accept or reject cookies.
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        8. Data retention
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We keep your data to enable us to fulfil our contract with you or to
                        provide services, whilst you are an active user of our site, app or
                        Costa Coffee Club, to administer and promote our awards and competitions
                        or where required by law or to protect legal rights.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We always look to keep your data for the minimum time in line with data
                        protection principles and our processes. For example, we keep:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Information on Costa Coffee Club members as follows. If you register
                                but do not collect and/or use any points using your card or app
                                within 12 months, your registration points will expire and we will
                                delete your Costa Coffee Club account information at that point. If
                                you do not collect and/or use points for 2 years, we will delete
                                your Costa Coffee Club account information.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Personal information related to products and services purchased
                                historically in our online shop, including Gift cards and eGifts,
                                for as long as the personal data is required in order for us to
                                fulfil our contract with you, as long as required to service any
                                related warranty and for 6 years from performance of our contract
                                with you.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Records of payment information in line with tax law and audit
                                requirements.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Customer feedback and correspondence with our customer services
                                teams for up to 2 years afterwards, depending on the nature of the
                                interaction and any applicable law, such as health and safety. This
                                enables us to respond to any questions or complaints.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                We may show advertisements on your social network newsfeed and to
                                other people like you for 2 years after our last contact from you.
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                Information to maintain records according to rules that apply to us.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        If you unsubscribe from marketing communications we keep a record of
                        this request indefinitely to ensure we do not send you direct marketing
                        again.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We may keep your data for longer if we cannot delete it for legal,
                        regulatory or technical reasons.
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        9.&nbsp; Your rights
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You have rights over your personal data.&nbsp;
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You can:
                    </p>
                    <ul className="py-0 pr-0 pl-10 m-0">
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                ask for a copy of your information;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                ask for information to be corrected;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                ask for information to be erased or deleted;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                ask for us to limit or restrict processing;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                object to us processing your data, in particular, where we do not
                                have to process the data to meet a contractual or other legal
                                requirement and in relation to processing for direct marketing
                                purposes, including profiling for direct marketing purposes;
                            </p>
                        </li>
                        <li className="list-inside text-left">
                            <p
                                className="inline p-0 m-0 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                            >
                                ask us to send you a copy in a structured digital format or ask for
                                us to send it to another party.
                            </p>
                        </li>
                    </ul>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Some rights, however, may be limited. We may be obliged by law or
                        regulation to keep information. We must respect other people’s privacy
                        as well, which means we may need to redact or remove information where
                        it includes personal data about someone else, even if it is connected to
                        your data. On occasion there may be a compelling legitimate interest to
                        keep processing data.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        If you want a copy of your data, to object to how we use your data, or
                        ask us to delete it or restrict how we use it or, please see ‘<a
                        href="#contact-details"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >Contact details</a
                    >’ below. To process a request from you, we may need to confirm your
                        identity to ensure we’re accessing the right data.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        You have a right to complain to an EU data protection authority. This
                        can be where you live, work or where the matter occurred. In the UK, the
                        authority is the Information Commissioner’s Office (the “ICO”).
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        10.&nbsp;Contact details
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        To exercise any of your rights or to withdraw consent you can
                        email:&nbsp;<a
                        href="mailto:mycostadata@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >mycostadata@costacoffee.com.</a
                    >
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        To discuss or change your Costa Coffee Club account details, including
                        preference settings, you can log into ‘My Account’ and go to (account
                        details) and Contact Preferences or contact customer services at&nbsp;<a
                        href="mailto:costacoffeeclub@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >costacoffeeclub@costacoffee.com</a
                    >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        For any queries relating to data protection, please contact Costa's Data
                        Protection Officer by email at&nbsp;<a
                        href="mailto:costadpo@costacoffee.com"
                        target="_self"
                        rel="noopener noreferrer"
                        className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                    >costadpo@costacoffee.com</a
                    >
                        or write to them at Data Protection Officer, Costa Limited, Costa House,
                        6 Porz Avenue, Houghton Hall Business Park, Houghton Regis, Dunstable,
                        Beds, LU5 5YG.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        We may change or update this notice from time to time. We will
                        communicate these as appropriate – for example, by updating our website
                        or, where legally required, by actively telling you about the changes.
                    </p>
                    <h4
                        className="mx-0 mt-0 mb-10 text-2xl text-red-900 md:text-4xl md:mx-0 md:mt-0 md:mb-8"
                    >
                        11.&nbsp;Which Costa entity is the controller?
                    </h4>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        The controller for your information is Costa Limited, Costa House, 6
                        Porz Avenue, Houghton Hall Business Park, Houghton Regis, Dunstable,
                        Beds, LU5 5YG.Costa Limited runs the Costa Coffee Club within Great
                        Britain.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        When you visit our{" "}
                        <a
                            href="https://www.facebook.com/CostaCoffee/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >Facebook </a
                        >page, Facebook Ireland will collect certain personal data from you and
                        they will give us anonymous analytics data about you. This is called
                        insight data and you can find more information about how Facebook
                        processes it{" "}
                        <a
                            href="https://protect-eu.mimecast.com/s/7OOoC31moT0LooOtqIvO_"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >here</a
                        >. Facebook and Costa are joint controllers over the insight data. For
                        any other processing we are separate controllers, please refer to
                        Facebook's{" "}
                        <a
                            href="https://protect-eu.mimecast.com/s/E55-C73qvso322wTRdkI8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >Data Policy</a
                        >
                        to understand how they process your personal data.
                        <br className=""/>
                        <br className=""/>If you would like to exercise your data subject rights
                        over this insight data please contact us or{" "}
                        <a
                            href="https://protect-eu.mimecast.com/s/koe8C82rwcLnWWEtzKznb"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-900 no-underline cursor-pointer focus:shadow-xs hover:text-red-700"
                        >Facebook</a
                        >.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Please remember that when you click a link to go from our website to
                        another website, our Privacy Policy no longer applies. Any browsing and
                        interaction on another website, is subject to that website's or
                        third-party notices and policies which we recommend you read. This
                        policy applies solely to data collected and processed by Costa Coffee.
                    </p>
                    <p
                        className="p-0 mx-0 mt-4 mb-8 font-sans leading-6 md:text-lg md:leading-7 md:mx-0 md:mt-4 md:mb-8"
                    >
                        Some stores using the Costa brand are franchisees. Franchisees are all
                        committed to protecting your privacy but, just to be clear, each Costa
                        franchisee is an independent business and is responsible for the
                        operation of its own stores and compliance with data protection law.
                    </p>
                </div>
            </article>

            <div className="mt-8">
                <Link to="/" className="bg-white rounded-md p-3 text-app-plum">
                    Go Back
                </Link>
            </div>
        </main>
    )
}

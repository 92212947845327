import Unauthenticated from 'Components/Layouts/Unauthenticated';
import {DateTime} from 'luxon';
import React, {Component} from 'react';
import {asset} from 'Services/BaseHelpers';

export default class Countdown extends Component {
    state = {
        time: DateTime.fromISO(process.env.MIX_RELEASE).diffNow(),
        date: DateTime.fromISO(process.env.MIX_RELEASE),
    };

    componentDidMount() {
        this.timer = setInterval(() => {
            const time = DateTime.fromISO(process.env.MIX_RELEASE).diffNow();

            this.setState({
                time,
            });
        }, 1000);
    }

    format(num) {
        return num.toString().padStart(2, '0');
    }

    render() {
        const date = this.state.date;
        const until = this.state.time.shiftTo('days', 'hours', 'minutes', 'seconds', 'milliseconds').toObject();

        if (until.seconds < 0) {
            window.location.reload();
        }

        return (
            <Unauthenticated width="max-w-2xl w-full px-4 py-8 lg:py-12" header={false}>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 text-white text-center">

                    <div className="text-3xl md:text-5xl mt-5 mb-2 text-white md:text-app-plum font-costa-display-wave">
                        The countdown is on!
                    </div>

                    <div className="flex w-full my-4 lg:my-8">
                        <div className="text-2xl md:text-6xl flex flex-row mx-auto">
                            <div className="mx-3">
                                <div>
                                    {this.format(until.days)}
                                </div>
                                <div className="text-base uppercase">
                                    days
                                </div>
                            </div>
                            <div className="mx-3">
                                <div>
                                    {this.format(until.hours)}
                                </div>
                                <div className="text-base uppercase">
                                    hours
                                </div>
                            </div>
                            <div className="mx-3">
                                <div>
                                    {this.format(until.minutes)}
                                </div>
                                <div className="text-base uppercase">
                                    minutes
                                </div>
                            </div>
                            <div className="mx-3">
                                <div>
                                    {this.format(until.seconds)}
                                </div>
                                <div className="text-base uppercase">
                                    seconds
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="my-3 text-lg">
                        Check back here on {date.toLocaleString(DateTime.DATE_FULL)}, when the game will be available.
                    </div>
                </div>
            </Unauthenticated>
        );
    }
}

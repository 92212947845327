import React from 'react';

import AdminLayout from '../AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, Toggle, WYSIWYG, DatePicker, Select, IsTranslatableWrapper} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';
import {ModalTrigger} from 'Components/Modal';
import PushNotificationModal from 'Components/Partials/Modals/PushNotificationModal'

import AdminPushNotificationsApi from 'Services/Api/Admin/PushNotifications';

class PushNotificationsStore extends React.Component {

    /**
     * @var success
     * @type {string}
     */
    success = 'Push notification ' + (this.props.match?.params?.pushNotification ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{push_notification_id: string}}
     */
    state = {
        push_notification_id: this.props.match?.params?.pushNotification ?? '',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.push_notification_id) {
            this.loadPushNotification();
        } else {
            this.props.setInitialValues({
                'supported_languages': Object.keys(window.base.languages),
                'title': '',
                'start_date_time': new Date(),
                'end_date_time': new Date(),
                'display_once_per_user': true,
                'content': null,
            });
        }
    }

    /**
     * @method loadPushNotification
     * @param {int} page
     */
    loadPushNotification = async (page = 1) => {
        const {push_notification_id} = this.state;

        const response = await AdminPushNotificationsApi.get(push_notification_id);

        let push_notification = response.data.data;

        this.props.setForm({
            'supported_languages': push_notification.supported_languages,
            'title': push_notification.title,
            'start_date_time': new Date(push_notification.start_date_time.iso_string),
            'end_date_time': push_notification.end_date_time ? new Date(push_notification.end_date_time.iso_string) : null,
            'display_once_per_user': push_notification.display_once_per_user,
            'content': push_notification.content,
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {push_notification_id} = this.state;

        if (push_notification_id) {
            return AdminPushNotificationsApi.patch(push_notification_id, form);
        } else {
            return AdminPushNotificationsApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {push_notification_id} = this.state;
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        let languages = Object.entries(window.base.languages).map((value, key) => {
            return {
                label: value[1],
                value: value[0]
            };
        });

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Push Notifications - {push_notification_id ? 'Update' : 'Create'} Push Notification
                        </h2>
                    </div>
                </div>

                {!window.base.features.allow_push_notifications &&
                    <p className="m-6 font-bold">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.allow_push_notifications &&
                    <div className="m-4">
                        <form
                            autoComplete="off"
                            className="divide-y divide-gray-200 lg:col-span-9"
                            onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (push_notification_id ? false : true))}
                        >
                            {alert !== null && (<Alert {...alert} />)}


                            <div className="grid grid-cols-2 gap-4 py-8 border-0">
                                {languages.length > 1 &&
                                    <div>
                                        <Select
                                            label="Supported Languages"
                                            value={form.supported_languages}
                                            onChange={(v) => handleInput('supported_languages', v)}
                                            options={languages}
                                            isMulti
                                        />
                                    </div>
                                }

                                <div className="my-auto">
                                    <Toggle
                                        label="Display Once Per User"
                                        value={form.display_once_per_user}
                                        onChange={v => handleInput('display_once_per_user', v)}
                                    />
                                </div>

                                <div className="col-span-2">
                                    <IsTranslatableWrapper
                                        label="Title"
                                        value={form.title}
                                        id="title"
                                        onChange={(v) => handleInput('title', v)}
                                        supported_languages={form.supported_languages}
                                        childComponent="Input"
                                        childProps={{}}
                                    />
                                </div>

                                <div>
                                    <DatePicker
                                        label="Start Date Time"
                                        selected={form.start_date_time}
                                        onChange={date => handleInput('start_date_time', date)}
                                        showTimeSelect={true}
                                    />
                                </div>

                                <div>
                                    <DatePicker
                                        label="End Date Time"
                                        selected={form.end_date_time}
                                        onChange={date => handleInput('end_date_time', date)}
                                        showTimeSelect={true}
                                    />
                                </div>

                                <div className="col-span-2">
                                    <IsTranslatableWrapper
                                        label="Notification Content"
                                        value={form.content}
                                        id="content"
                                        onChange={(v) => handleInput('content', v)}
                                        supported_languages={form.supported_languages}
                                        childComponent="WYSIWYG"
                                        childProps={{}}
                                    />
                                </div>

                                <div className="col-span-2 text-center">
                                    <ModalTrigger
                                        component={PushNotificationModal}
                                        props={{
                                            pushNotification: form,
                                            preview: true,
                                        }}
                                    >
                                        <PrimaryButton
                                            text="Preview"
                                            onClick={null}
                                        />
                                    </ModalTrigger>
                                </div>
                            </div>

                            <div className="p-6 flex justify-end">
                                <PrimaryButton
                                    text="Save"
                                    working={working}
                                />
                            </div>
                        </form>
                    </div>
                }
            </AdminLayout>
        )
    }
}

export default FormHandler(PushNotificationsStore);

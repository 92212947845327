import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from '../AdminLayout';

import {Alert, Loading, PaginationBar} from 'Components/Partials';
import AdminButtons from 'Components/Partials/AdminButtons';
import Delete from 'Components/Partials/Modals/Delete';
import {PrimaryButton, DangerButton} from 'Components/Button';

import AdminPushNotificationsApi from 'Services/Api/Admin/PushNotifications';
import {translationFromJson} from 'Services/TranslationHelpers2';

export default class PushNotificationsIndex extends React.Component {

    state = {
        working: true,
        alert: null,
        pushNotifications: [],
    };

    componentDidMount() {
        this.loadPushNotifications();
    }

    loadPushNotifications = async (page = 1) => {
        this.setState({ 
            working: true,
            alert: null,
        });

        const response = await AdminPushNotificationsApi.get(null, {
            page
        });

        this.setState({
            working: false,
            pushNotifications: response.data,
        });
    }

    handleDelete = async (pushNotification) => {
        this.setState({ working: true });

        let data = {
            confirmed: true
        };

        const response = await AdminPushNotificationsApi
            .delete(pushNotification.id, 'delete-push-notification', 204, {data});

        if (response.status !== 204) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message
                },
                working: false
            });
            return;
        }

        this.loadPushNotifications();
    }

    /**
     * @method getButtonsForPushNotification
     * @param {object} pushNotification
     */
    getButtonsForPushNotification = (pushNotification) => {
        return [
            {
                type: "link",
                to: "/admin/push-notifications/store/"+pushNotification.id,
                text: "Update"
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'push notification',
                    onDelete: () => this.handleDelete(pushNotification)
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, pushNotifications} = this.state;

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Push Notifications
                        </h2>
                    </div>
                </div>

                {!window.base.features.allow_push_notifications &&
                    <p className="m-6 font-bold">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.allow_push_notifications &&
                    <div className="m-4">
                        <div className="flex">
                            <Link to={"/admin/push-notifications/store"}>
                                <PrimaryButton
                                    text="Click here to add a new Push Notification."
                                />
                            </Link>
                        </div>

                        <div className="mt-8">
                            {working && (<Loading />)}

                            {alert !== null && (<Alert {...alert} />)}

                            {!working &&
                                <>
                                    { pushNotifications?.meta?.total == 0 &&
                                        <p>Push notifications will appear here.</p>
                                    }

                                    { pushNotifications?.meta?.total != 0 &&
                                        <>
                                            <div className="overflow-x-auto">
                                                <table className="table-auto">
                                                    <thead>
                                                        <tr>
                                                            <th className="px-4 py-2">Title</th>
                                                            <th className="px-4 py-2">Start Date</th>
                                                            <th className="px-4 py-2">End Date</th>
                                                            <th className="px-4 py-2">Sent</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { pushNotifications.data.map((pushNotification, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="border px-4 py-2">{translationFromJson(pushNotification.title)}</td>
                                                                    <td className="border px-4 py-2">{pushNotification.start_date_time.date_time_timezone}</td>
                                                                    <td className="border px-4 py-2">{pushNotification.end_date_time?.date_time_timezone}</td>
                                                                    <td className="border px-4 py-2">{pushNotification.sent ? 'Yes' : 'No'}</td>
                                                                    <td className="border px-4 py-2 text-center">
                                                                        <AdminButtons buttons={this.getButtonsForPushNotification(pushNotification)} />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {pushNotifications && (
                                                <div className="mt-12 mb-4">
                                                    <PaginationBar
                                                        total={pushNotifications.meta.total}
                                                        pageCount={pushNotifications.meta.last_page}
                                                        page={pushNotifications.meta.current_page}
                                                        goToPage={this.loadPushNotifications}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                }
            </AdminLayout>
        )
    }
}
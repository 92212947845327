import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, Label} from 'Components/Form';
import {Alert} from 'Components/Partials';
import {DisplayTimeZone, DisplayLanguage} from 'Components/Settings';

import {User} from 'Services';
import Me from 'Services/Api/Me/Me';

import SettingsPage from '../SettingsPage';

class Profile extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your details have been updated successfully';

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.props.setForm({
            full_name: User.data.full_name,
            email: User.data.email,
        });
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        return (
            <SettingsPage>
                <form
                    autoComplete="off"
                    className="divide-y divide-gray-200 lg:col-span-9"
                    onSubmit={(e) => handleSubmit(e, Me.update, this.success)}
                >
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
                    </div>

                    <div className="p-6">
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <Input
                                    label="First Name"
                                    value={form.full_name}
                                    id="full_name"
                                    onChange={(v) => handleInput('full_name', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="Last Name"
                                    value={form.last_name}
                                    id="last_name"
                                    onChange={(v) => handleInput('last_name', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="Email"
                                    value={form.email}
                                    id="email"
                                    onChange={(v) => handleInput('email', v)}
                                    readOnly={!window.base.features.allow_email_change}
                                />
                            </div>

                            <div>
                                <Label label="TimeZone" htmlFor="timezone" />
                                <DisplayTimeZone editMode={true} />
                            </div>

                            {Object.keys(window.base.languages).length > 1 &&
                                <div>
                                    <Label label="Language" htmlFor="language" />
                                    <DisplayLanguage editMode={true} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="p-6 flex justify-between">
                        <PrimaryButton
                            text="Save"
                            working={working}
                        />
                    </div>
                </form>
            </SettingsPage>
        );
    }
}

export default FormHandler(Profile);

import React from 'react';
import {NavLink} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';

import {PrimaryButton} from 'Components/Button';
import {ModalTrigger} from 'Components/Modal';

export default class AdminButtons extends React.Component {
	/**
	 * @var state
	 * @type {{show: boolean}}
	 */
	state = {
		show: false,
	};

	/**
	 * @method render
	 * @return {JSX.Element}
	 */
	render() {
		const {buttons} = this.props;
		const {show} = this.state;

		const styles = 'block py-2 px-4 font-medium text-white hover:bg-gray-100 hover:text-black leading-5';

		return (
			<div>
				<div 
					className="bg-light-blue-700 text-white px-3 py-2 rounded-md shadow-lg cursor-pointer"
					onClick={() => this.setState({show: !show})}
					title="Click for more actions..."
				>
	                <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown} />
	            </div>

				<div className={`
					absolute mt-2 rounded-md shadow-lg z-20 bg-light-blue-700 text-left
					py-1 ring-1 ring-black ring-opacity-5 
					${show ? '' : 'hidden'}
				`}>
					{buttons.map((button, i) => {
						if (button.type === "link") {
							return (
								<NavLink to={button.to} className={styles} key={i}>
									{button.text}
								</NavLink>
							);
						}
						else if (button.type === "onClick") {
							return (
								<div onClick={button.onClick} className={styles} key={i}>
									{button.text}
								</div>
							);
						}
						else if (button.type === "modalTrigger") {
							return (
								<ModalTrigger
						            component={button.component}
						            props={button.props}
						            key={i}
						        >
						            <div className={styles}>{button.text}</div>
						        </ModalTrigger>
							);
						}
					})}
				</div>
			</div>
		);
	}
}
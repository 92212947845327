import React from 'react';

import BaseGame from 'Components/Games/Base/Game';
import {Game} from './Screens';

export default class CatchGame extends BaseGame {
    /**
     * @var iconColour
     * @type {string}
     */
    iconColour = 'text-purple-dark';
    timeLimit = false;

    screens = {
        game: Game
    };
}

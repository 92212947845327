import {EventEmitter} from 'events';
import {Settings as LuxonSettings} from 'luxon';
import isEqual from 'lodash/isEqual';

import SettingsApi from 'Services/Api/Settings/Settings';

class Settings extends EventEmitter {
    /**
     * @var data
     * @type {null|object}
     */
    data = null;

    /**
     * @method refresh
     * @return {Promise<void>}
     */
    refresh = async (refreshPage = false) => {
        const request = await SettingsApi.get(null);

        let new_data = request.success ? request.data.settings : null;

        if (! isEqual(this.data, new_data)) {
            this.data = new_data;

            if (new_data.language) {
                LuxonSettings.defaultLocale = new_data.language.replace('_', '-');
            }

            // TODO rather than the refresh below (or the notice to refresh the page in DisplayTimeZone and in DisplayLanguage)
            // get it to automatically remount the app and update via an api request the translations in window.base.
            //
            // (In DisplayTimeZone and DisplayLanguage confirm the change with the user before the update
            // "This will refresh the page so you will loose any unsaved data, are you sure?")

            if (refreshPage) {
                location.reload();
            }

            this.emit('change');
        }
    };
}

export default new Settings();

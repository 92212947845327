import React from 'react';

import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {AsyncPaginate} from 'react-select-async-paginate';

import Label from './Label';

import {isAdminPage} from 'Services/BaseHelpers';

/*
Examples

Select ...
<Select
    label="Role"
    value={form.role}
    id="role"
    onChange={(v) => handleInput('role', v)}
    options={roles}
/>

CreatableSelect ...
<Select
    label="Tags"
    value={form.tags}
    id="tags"
    onChange={v => handleInput('tags', v)}
    options={options}
    isMulti
    isCreatable
/>

AsyncPaginate (the searchCallback api request must have a 'search' filter applied to it) ...
<Select
    label="Nominated By"
    value={form.nominated_by}
    id="nominated_by"
    onChange={(v) => handleInput('nominated_by', v)}
    options={form.nominated_by ? [{
            label: form.nominated_by_email,
            value: form.nominated_by
        }] : null}
    isAsync
    searchCallback={(page, search) => UsersApi.get(null, {page, search})}
    searchLabelKey="email"
    allowNull={true}
/>
*/

export default class Select extends React.Component {
    /**
     * @var state
     * @type {{isLoading: boolean}}
     * @type {{newOptions: array}}
     */
    state = {
        isLoading: false,
        newOptions: [],
    };

    /**
     * @method handleChange
     * @param {object} option
     */
    handleChange = (option) => {
        const {onChange, isMulti, isAsync} = this.props;

        if (!option) {
            onChange(null);
        }

        if (isAsync) {
            this.setState({
                newOptions: [option],
            });
        }

        isMulti ? 
            onChange(option?.map(o => { return o.value; })) : 
            onChange(option?.value);
    };

    /**
     * @method handleCreate
     * @param {object} option
     */
    handleCreate = (option) => {
        this.setState({ isLoading: true });

        const { newOptions } = this.state;
        const newOption = {
            label: option,
            value: option
        };

        this.setState({
            isLoading: false,
            newOptions: [...newOptions, newOption],
        });
    }

    /**
     * @method handleSearch
     * @param {string} search
     * @return {object}
     */
    handleSearch = async (search, loadedOptions, { page }) => {
        const {searchCallback, searchLabelKey, allowNull} = this.props;

        const response = await searchCallback(
            page, search
        );

        let options = response.data.data.map((option, key) => {
            return {
                label: option[searchLabelKey],
                value: option.id
            };
        });

        if (!search && allowNull && page === 1) {
            options = [
                {
                    label: 'None',
                    value: null
                },
                ...options,
            ];
        }

        return {
            options,
            hasMore: response.data.meta.current_page !== response.data.meta.last_page,
            additional: {
                page: page + 1,
            },
        };
    }

    /**
     * @method getTotalOptions
     * @return {object}
     */
    getTotalOptions = () => {
        const {options} = this.props;
        const {newOptions} = this.state;

        let totalOptions = newOptions;

        if (options) {
            totalOptions = totalOptions.concat(options);
        }

        return totalOptions;
    }

    /**
     * @method getSelectedValue
     * @param {object} totalOptions
     * @return {object}
     */
    getSelectedValue = (totalOptions) => {
        const {value, isMulti} = this.props;

        let selectedValue = null;

        if (value && totalOptions) {
            if (isMulti) {
                selectedValue = totalOptions.filter(o => value.indexOf(o.value) !== -1);
            } else {
                const o = totalOptions.filter(o => value === o.value);

                if (o.length !== 0) {
                    selectedValue = o[0];
                }
            }
        }

        return selectedValue;
    }

    /**
     * @method getStyles
     * @return {object}
     */
    getStyles = () => {
        let styles = {};

        if (!isAdminPage()) {
            styles = {}
        }

        return styles;
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {containerClassName, label, id, onChange, isCreatable, isAsync, instructions} = this.props;
        const {isLoading} = this.state;

        let totalOptions = this.getTotalOptions();
        let selectedValue = this.getSelectedValue(totalOptions);
        let styles = this.getStyles();

        return (
            <div className={containerClassName}>
                {label && (<Label label={label} htmlFor={id} instructions={instructions} />)}

                {isCreatable &&
                    <CreatableSelect
                        {...this.props}
                        value={selectedValue}
                        options={totalOptions}
                        onChange={this.handleChange}
                        menuPosition="fixed"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onCreateOption={this.handleCreate}
                        isClearable
                        styles={styles}
                    />
                }

                {isAsync &&
                    <AsyncPaginate
                        {...this.props}
                        value={selectedValue}
                        onChange={this.handleChange}
                        menuPosition="fixed"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        loadOptions={this.handleSearch}
                        cacheOptions
                        styles={styles}
                        additional={{
                            page: 1,
                        }}
                    />
                }

                {!isCreatable && !isAsync &&
                    <ReactSelect
                        {...this.props}
                        value={selectedValue}
                        options={totalOptions}
                        onChange={this.handleChange}
                        menuPosition="fixed"
                        styles={styles}
                    />
                }
            </div>
        )
    }
};

import React from 'react';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';

import AdminLayout from '../AdminLayout';

import {Alert, Loading, PaginationBar} from 'Components/Partials';
import AdminButtons from 'Components/Partials/AdminButtons';
import Delete from 'Components/Partials/Modals/Delete';
import AdminSendEmailModal from 'Components/Partials/Modals/AdminSendEmailModal';
import {PrimaryButton} from 'Components/Button';
import {Input} from 'Components/Form';

import AdminUsersApi from 'Services/Api/Admin/Users';

class UsersIndex extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        alert: null,
        users: [],
        filters: {
            search: ''
        },
        order: {
            order_by: 'full_name',
            order: 'asc'
        },
        hardPassword: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadUsers();

        {window.base.using_hard_password &&
            this.fetchHardPassword();
        }
    }

    /**
     * @method loadUsers
     * @param {integer} page
     */
    loadUsers = async (page = 1) => {
        this.setState({
            working: true,
            alert: null,
        });

        const response = await AdminUsersApi.getUsers({
            page,
            ...this.state.filters,
            ...this.state.order
        });

        this.setState({
            working: false,
            users: response.data,
        });
    }

    /**
     * @method fetchHardPassword
     */
    fetchHardPassword = async () => {
        const response = await AdminUsersApi.getHardPassword();

        this.setState({
            hardPassword: response.data.hard_password,
        });
    }

    /**
     * @method handleDelete
     * @param {object} user
     */
    handleDelete = async (user) => {
        this.setState({ working: true });

        const response = await AdminUsersApi.deleteUser(user.id, {
            confirmed: true
        });

        if (response.status !== 204) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message
                },
                working: false
            });
            return;
        }

        this.loadUsers();
    }

    /**
     * @method handleSearch
     * @param {string} search
     */
    handleSearch = (search) => {
        window.clearTimeout(this.searchTimeout);

        this.setState({
            filters:{
                ...this.state.filters,
                search
            }
        }, () => {
            this.searchTimeout = setTimeout(this.loadUsers, 250);
        });
    };

    /**
     * @method renderOrderIcon
     * @return {JSX.Element}
     */
    renderOrderIcon = () => {
        const {order} = this.state;

        return (
            <FontAwesomeIcon icon={order.order === 'asc' ? faArrowDown : faArrowUp} />
        );
    };

    /**
     * @method handleOrderBy
     * @param {string} orderBy
     */
    handleOrderBy = (orderBy) => {
        this.setState({
            order: {
                order_by: orderBy,
                order: this.state.order.order_by === orderBy ? (this.state.order.order === 'asc' ? 'desc' : 'asc') : 'asc'
            }
        }, this.loadUsers);
    };

    /**
     * @method getButtonsForUser
     * @param {object} user
     */
    getButtonsForUser = (user) => {
        let buttons = [
            {
                type: "link",
                to: "/admin/users/show/"+user.id,
                text: "View"
            },
            {
                type: "link",
                to: "/admin/users/store/"+user.id,
                text: "Update"
            }
        ];

        if (user.is_admin || !window.base.using_hard_password) {
            buttons.push({
                type: "link",
                to: "/admin/users/update-password/"+user.id,
                text: "Reset Password"
            });

            buttons.push({
                type: "modalTrigger",
                component: AdminSendEmailModal,
                props: {
                    user,
                    onSubmit: AdminUsersApi.sendPasswordResetLink,
                    emailTitle: 'password reset link'
                },
                text: 'Send Password Reset Link'
            });
        }

        buttons.push({
            type: "modalTrigger",
            component: AdminSendEmailModal,
            props: {
                user,
                onSubmit: AdminUsersApi.sendUserInvite,
                emailTitle: 'user invite',
                userInvite: true
            },
            text: 'Send User Invite'
        });

        if (window.base.features.notifications) {
            buttons.push({
                type: "link",
                to: "/admin/user-comms/"+user.id,
                text: 'Comms'
            });
        }

        buttons.push({
            type: "modalTrigger",
            component: Delete,
            props: {
                itemName: 'user',
                onDelete: () => this.handleDelete(user)
            },
            text: 'Delete'
        });

        return buttons;
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, users, order, filters, hardPassword} = this.state;

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Users
                            {window.base.using_hard_password &&
                                <> (Hard password in use: {hardPassword})</>
                            }
                        </h2>
                    </div>
                </div>

                <div className="ml-4 mr-4 mt-4">
                    <div className="flex">
                        <Link to={"/admin/users/store"}>
                            <PrimaryButton
                                text="Click here to add a new User."
                            />
                        </Link>

                        <div className="w-full pl-4">
                            <Input
                                type="text"
                                value={filters.search}
                                onChange={this.handleSearch}
                                placeholder="Search..."
                            />
                        </div>
                    </div>

                    <div className="mt-8">
                        {working && (<Loading />)}

                        {alert !== null && (<Alert {...alert} />)}

                        {!working &&
                            <>
                                { users?.meta?.total == 0 &&
                                    <p>Users will appear here.</p>
                                }

                                { users?.meta?.total != 0 &&
                                    <>
                                        <div className="overflow-x-auto">
                                            <table className="table-auto">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left p-2" onClick={() => this.handleOrderBy('full_name')}>
                                                            <div className="flex cursor-pointer">
                                                                {order.order_by === 'full_name' && this.renderOrderIcon()}
                                                                Name
                                                            </div>
                                                        </th>
                                                        <th className="text-left p-2" onClick={() => this.handleOrderBy('email')}>
                                                            <div className="flex cursor-pointer">
                                                                {order.order_by === 'email' && this.renderOrderIcon()}
                                                                Email
                                                            </div>
                                                        </th>
                                                        {window.base.features.groups &&
                                                            <th className="text-left p-2">
                                                                Group
                                                            </th>
                                                        }
                                                        <th className="text-left p-2">
                                                            Admin
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { users.data.map((user, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="border px-4 py-2">
                                                                    {user.full_name}
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {user.email}
                                                                </td>
                                                                {window.base.features.groups &&
                                                                    <td className="border px-4 py-2">
                                                                        {user.group ? user.group.name : null}
                                                                    </td>
                                                                }
                                                                <td className="border px-4 py-2">
                                                                    {user.is_admin ? 'Yes' : 'No'}
                                                                </td>
                                                                <td className="border px-4 py-2 text-center">
                                                                    <AdminButtons buttons={this.getButtonsForUser(user)} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        {users && (
                                            <div className="mt-12 mb-4">
                                                <PaginationBar
                                                    total={users.meta.total}
                                                    pageCount={users.meta.last_page}
                                                    page={users.meta.current_page}
                                                    goToPage={this.loadUsers}
                                                />
                                            </div>
                                        )}
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </AdminLayout>
        )
    }
}

export default UsersIndex;

import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Base from './Base';

/**
 * @function PrimaryButton
 * @param {function} onClick
 * @param {string} className
 * @param {string} text
 * @param {boolean} working
 * @param {boolean} disabled
 * @param {string} [fontSize]
 * @param {boolean} [active]
 * @param {string} [activeStyles]
 * @param {string} [inactiveStyles]
 * @return {JSX.Element}
 * @constructor
 */
const PrimaryButton = (
    {
        onClick, className, text, working, disabled, fontSize = 'text-sm',
        active = true, activeStyles = 'bg-white border text-app-plum border-transparent', inactiveStyles = '',
    },
) => {
    let classes = `
        ${active ? activeStyles : inactiveStyles} rounded-lg shadow-sm py-2 px-4 inline-flex
        justify-center items-center font-medium text-white ${fontSize}
        ${
        !disabled && !working ? 'hover:bg-opacity-70 focus:outline-none' : 'opacity-50'
    }
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <Base
            className={classes}
            type={onClick !== undefined ? 'button' : 'submit'}
            onClick={onClick}
            disabled={disabled}
            working={working}
            text={text}
        />
    );
};

export default PrimaryButton;

import React from 'react';

import AdminLayout from '../AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminUsersApi from 'Services/Api/Admin/Users';

class UsersUpdatePassword extends React.Component {

    /**
     * @var success
     * @type {string}
     */
    success = 'User password update successfully';

    /**
     * @var state
     * @type {{user_id: string}}
     */
    state = {
        loading: true,
        user_id: this.props.match.params.user,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.props.setInitialValues({
            new_password: '',
            new_password_confirmation: '',
        });

        this.setState({loading: false});
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {user_id} = this.state;

        return AdminUsersApi.updatePassword(user_id, form);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        return (
            <AdminLayout>
                <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Admin Users - Update Password
                        </h2>
                    </div>
                </div>

                <div className="m-4">
                    <form
                        autoComplete="off"
                        className="divide-y divide-gray-200 lg:col-span-9"
                        onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, true)}
                    >
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid grid-cols-2 gap-4 mb-4 border-0">
                            <div>
                                <Input
                                    label="New Password"
                                    type="password"
                                    value={form.new_password}
                                    id="new_password"
                                    onChange={v => handleInput('new_password', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="New Password Confirmation"
                                    type="password"
                                    value={form.new_password_confirmation}
                                    id="new_password_confirmation"
                                    onChange={v => handleInput('new_password_confirmation', v)}
                                />
                            </div>
                        </div>

                        <div className="p-6 flex justify-end">
                            <PrimaryButton
                                text="Save"
                                working={working}
                            />
                        </div>
                    </form>
                </div>
            </AdminLayout>
        )
    }
}

export default FormHandler(UsersUpdatePassword);

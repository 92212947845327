import ReactGa, {TrackerNames} from 'react-ga';
import User from 'Services/User';
import Cookies from "Services/Cookies";

class GoogleAnalytics {
    shouldReport = false;
    /**
     * @type {string|null}
     */
    previousPath = null;

    constructor() {
        this.setupGa();
        Cookies.on('update', this.setupGa);
    }

    setupGa = () => {
        if (process.env.NODE_ENV === 'production' && process.env.MIX_GOOGLE_ANALYTICS && Cookies.cookie === 'accept') {
            this.shouldReport = true;
            ReactGa.initialize(process.env.MIX_GOOGLE_ANALYTICS);

            if (User.loggedIn) {
                ReactGa.set({
                    userId: User.id,
                });
            }

            User.on('change', this.updateUser);
        }
    }

    updateUser = () => {
        ReactGa.set({
            userId: User.loggedIn ? User.id : null,
        });
    };

    /**
     * @param {string} path
     * @param {TrackerNames} [trackerNames]
     * @param {string} [title]
     */
    pageView = (path, trackerNames, title) => {
        if (this.shouldReport && this.previousPath !== path) {
            this.previousPath = path;
            ReactGa.pageview(path, trackerNames, title)
        }
    }
}

export default new GoogleAnalytics;

import React from 'react';

import Header from 'Components/Header';

import { User } from 'Services';
import { asset } from "Services/BaseHelpers";

export default class Unauthenticated extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {children, width = 'w-full lg:w-1/3 px-6 lg:px-0', header = true} = this.props;

        return (
            <>
                {header && User.loggedIn && (
                    <div className={"justify-start"}>
                        <Header/>
                    </div>
                )}
                <div className="w-full md:min-h-screen flex flex-col md:flex-row">
                    <div className={"w-full md:w-1/3 min-h-1/3 lg:min-h-screen max-h-screen bg-app-plum flex flex-col items-center justify-center"}>
                        <img className="w-36 lg:w-48 mx-auto py-5 lg:py-20" src={asset("/images/logo.png")} alt="Costa" />

                        <img className="w-40 lg:w-72 mx-auto py-5 lg:py-20" src={asset("/images/frappe.png")} alt="Costa" />
                    </div>
                    <div className="w-full md:w-2/3 min-h-2/3 md:min-h-screen bg-app-plum md:bg-bubbles bg-no-repeat bg-cover flex flex-col items-center justify-center mt-20 md:mt-0">
                        <div className={width}>
                            {children}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
